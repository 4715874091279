import es from "./lang/es";

enum ELang {
  ES = 'es',
}

export default function translator(word: any, lang: ELang = ELang.ES): string {
  switch (lang) {
    case ELang.ES:
      if (Object.keys(es).includes(word)) {
        // @ts-ignore
        return es[word];
      }
      break;
  }

  return word
}
