import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../../Request";
import requestMiddlewares from "../../../requestMiddlewares";

export default async function (
  dispatch: Dispatch,
  id: string,
  raceId: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('circuits', 'race-remove')
    .setPathValues('ID', id)
    .setPathValues('RID', raceId)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
