import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../../Request";
import requestMiddlewares from "../../../requestMiddlewares";
import ICircuitEventInscription from "../../../../interfaces/ICircuitEventInscription";
import IPager from "../../../../interfaces/IPager";

export default async function (
  dispatch: Dispatch,
  id: string,
  eventId: string,
): Promise<IResponse<IPager<ICircuitEventInscription>>> {
  dispatch(startLoading());

  const request = new RACARequest('circuits', 'events-inscriptions')
    .setPathValues('ID', id).setPathValues('EID', eventId)
    .setQueryParameters({
      perPage: 100,
    })

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
