import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICreateCompanyRequest {
  title: string,
  price: number,
  peopleCount: number;
  description?: string;
}

export default async function(
  dispatch: Dispatch,
  raceId: string,
  circuitId: string,
  input: ICreateCompanyRequest,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('circuits', 'race-update')
    .setPathValues('ID', circuitId)
    .setPathValues('RID', raceId)
    .setData(input)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
