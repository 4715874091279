import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ERoutes from "../../core/enums/ERoutes";
import IPermission from "../../core/interfaces/IPermission";
import { setSession } from "../../core/store/slices/sessionSlice";
import { RootState } from "../../core/store/store";
import { getSession } from "../../core/utils/sessionStorage";
import Body from "./Body";
import Sidebar from "./Sidebar";

interface ISecureViewParameters {
  children: any;
  permission?: IPermission;
}
export default function SecureView(data: ISecureViewParameters) {
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.session);
  const permissions = useSelector((state: RootState) => state.permissions);

  const navigator = useNavigate();

  const validatePermission = (): void => {
    if (!data.permission) return;

    // if (!hasPermission(permissions, data.permission)) {
    //   sweetalert2.fire(
    //     `Error`,
    //     `No tienes permiso para acceder a esta sección`,
    //     'error',
    //   )
    //   navigator(ERoutes.HOME)
    // }
  };

  useEffect(() => {
    const data = getSession();
    if (data) {
      dispatch(setSession(data));
    } else {
      return navigator(ERoutes.AUTH_LOGIN);
    }

    validatePermission();
  }, [navigator.name]);

  return (
    <Body>
      <Sidebar />
      <div className="page-wrapper">
        <div className="container-fluid overflow-hidden">
          {data.children && data.children}
        </div>
      </div>
    </Body>
  );
}
