import {useDispatch} from "react-redux";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import sweetalert2 from "sweetalert2";
import createTipRequest from "../../core/api/requests/tips/createTipRequest";
import levels from "../../core/utils/levels";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import translator from "../../core/utils/translator";

type IInput = {
  onClose(): void;
  show: boolean;
}

export default function (data: IInput) {
  const dispatch = useDispatch()

  const [ content, setContent ] = useState<string>('')
  const [ title, setTitle ] = useState<string>('')
  const [ owner, setOwner ] = useState<string>('')
  const [ level, setLevel ] = useState<string>('beginner')

  const save = async () => {
    if (!title || !content || !owner || !level) {
      sweetalert2.fire(
        `Atención`,
        `Debes completar todos los campos para continuar.`,
        'error',
      )
      return
    }

    const response = await createTipRequest(
      dispatch,
      {
        title,
        content: {
          text: content,
        },
        owner,
        type: 'tip',
        level,
      }
    )

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Consejo creado exitosamente.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else {
      sweetalert2.fire(
        `Error`,
        translator(response?.data?.message),
        'error',
      )
    }

    setTitle('')
    setLevel('')
    setContent('')
  }

  return <Modal onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton closeLabel={'X'}>
      <Modal.Title>Crear Consejo</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Form.Group className="mb-3 col-12">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Creador</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setOwner(e.target.value)}
            value={owner}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Contenido</Form.Label>
          <Form.Control
            as={"textarea"}
            rows={10}
            onChange={(e) => setContent(e.target.value)}
            value={content}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Nivel</Form.Label>
          <Form.Select value={level} className={'col-12'} onChange={(e) => setLevel(e.target.value)}>
            {levels.levelsSelect.map((l) => <option key={l.key} value={l.key}>{l.value}</option>)}
          </Form.Select>
        </Form.Group>
      </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={save}
      >
        Crear
      </Button>
    </Modal.Footer>
  </Modal>
}
