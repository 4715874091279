import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../../Request";
import requestMiddlewares from "../../../requestMiddlewares";
import ICircuitMetrics from "../../../../interfaces/ICircuitMetrics";

export default async function (
  dispatch: Dispatch,
  circuitId: string,
): Promise<IResponse<ICircuitMetrics>> {
  dispatch(startLoading());

  const request = new RACARequest('circuits', 'get-metrics')
    .setPathValues('ID', circuitId)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
