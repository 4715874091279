import SecureView from "../../shared/common/SecureView";
import {Badge, Button, Form, InputGroup, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import findCircuitRequest from "../../core/api/requests/circuits/findCircuitRequest";
import ERoutes from "../../core/enums/ERoutes";
import Paginator from "../../shared/common/Paginator";
import ICircuit from "../../core/interfaces/ICircuit";
import hasPermission from "../../core/utils/hasPermission";

const permission: IPermission = {
  module: EModules.CIRCUITS,
  method: ECommonPermissions.FIND,
}

export default function CircuitList() {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ circuits, setCircuits ] = useState<ICircuit[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const changePageAndFind = (page: number) => {
    find(page);
  }

  const find = async (pageByParam?: number) => {
    if (pageByParam) setPage(pageByParam)

    const response = await findCircuitRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
    )

    if (response?.data) {
      setCircuits(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [query])

  return <SecureView permission={permission}>
    <Row>
      <Card className={'col-12'}>
        <CardBody title={`Circuitos ${count}`}>
          <div className={'row mt-5'}>
            <div className={'col-9'}>
              <InputGroup>
                <Form.Control
                  placeholder="Buscar por nombre"
                  aria-label="Buscar por nombre"
                  aria-describedby="Buscar por nombre"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={(key) => key.key === 'Enter' && find(1)}
                />
                <Button
                  variant="outline-primary"
                  id="button-addon2"
                  onClick={() => find(1)}
                >
                  <i className={'ti-search'}></i> Buscar
                </Button>
              </InputGroup>
            </div>

            <div className={'col-3'}>
              <Link
                to={ERoutes.CIRCUITS_CREATE}
                className="btn btn-info float-right"
              >Nuevo Circuito
              </Link>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered m-t-30 table-hover contact-list">
              <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              {circuits.map((circuit) => {
                return <tr key={circuit.id}>
                  <td>{circuit.id}</td>
                  <td>{circuit.name}</td>
                  <td>
                    {hasPermission(permissions, { module: permission.module, method: ECommonPermissions.FIND_BY_ID }) &&
                      <Button size={'sm'} variant={'secondary'} onClick={() => navigator(ERoutes.CIRCUITS_VIEW.replace(':id', circuit.id))}>
                        <i className={'ti-eye'}></i> Ver
                      </Button>
                    }
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>

            <Paginator
              total={totalPages}
              page={page}
              goTo={changePageAndFind}
            />
          </div>


        </CardBody>
      </Card>
    </Row>
  </SecureView>
}
