import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import IBasicFilters from "../../../interfaces/IBasicFilters";
import requestMiddlewares from "../../requestMiddlewares";
import IPager from "../../../interfaces/IPager";
import ITip from "../../../interfaces/ITip";

export default async function (
  dispatch: Dispatch,
  filters: IBasicFilters,
): Promise<IResponse<IPager<ITip>>> {
  dispatch(startLoading());

  const request = new RACARequest('tips', 'list')
    .setQueryParameters({
      ...filters,
      type: 'tip',
    });

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
