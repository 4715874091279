import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import { ReactComponent as EyeLine } from "../../../eye-line.svg";
import { ReactComponent as Eye } from "../../../eye.svg";
import Body from "../../../shared/common/Body";

import logo from "../../../shared/styles/images/logo-transparent-red.png";

import sweetalert2 from "sweetalert2";
import loginRequest from "../../../core/api/requests/auth/loginRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import { setSession } from "../../../core/store/slices/sessionSlice";
import { getSession, saveSession } from "../../../core/utils/sessionStorage";
import translator from "../../../core/utils/translator";
import LoginStyles from "./login.module.css";

const TITLE = "login";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);

  const [error, setError] = useState<string>("");

  const [remember, setRemember] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const signIn = async (e: any) => {
    e.preventDefault();

    const response = await loginRequest(dispatch, {
      email,
      password,
      refreshToken: remember,
      context: {
        latitude,
        longitude,
      },
    });

    if (response.statusCode === EHttpStatusCodes.OK) {
      saveSession(response.data);
      return redirectToDashboard();
    } else {
      sweetalert2.fire(`Error`, translator(response.data.message), "error");
    }
  };

  const redirectToDashboard = () => navigate(ERoutes.DASHBOARD);

  useEffect(() => {
    const data = getSession();
    if (data) {
      dispatch(setSession(data));
      redirectToDashboard();
    }

    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
    if (email && password) {
      setButtonActive(true);
    } else {
      setButtonActive(false);
    }
  }, [email, password]);

  return (
    <Body title={TITLE}>
      <section id="wrapper" className={"fullWidth"}>
        <div className="login-register login-picture">
          <div className="login-box card">
            <div className="card-body">
              <form className="form-horizontal form-material" onSubmit={signIn}>
                <div className={LoginStyles.logoContainer}>
                  <img className={LoginStyles.logo} src={logo} />
                </div>
                <h3 className="text-center m-b-20">Iniciar Sesión</h3>
                <div className="form-group ">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="email"
                      required={true}
                      placeholder="Usuario"
                      value={email}
                      onChange={(e: any) => setEmail(e.target?.value)}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type={showPassword ? "text" : "password"}
                      required={true}
                      placeholder="Contraseña"
                      value={password}
                      onChange={(e: any) => setPassword(e.target?.value)}
                    />
                  </div>
                  <div className={LoginStyles.passwordIcon}>
                    {showPassword ? (
                      <Eye
                        className={LoginStyles.eyeLogo}
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <EyeLine
                        className={LoginStyles.eyeLogo}
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12">
                    <div className="d-flex no-block align-items-center">
                      <div className="ml-auto">
                        <Link
                          to={ERoutes.AUTH_PASSWORD_RECOVERY_REQUEST}
                          id="to-recover"
                          className="text-muted"
                        >
                          <i className="fas fa-lock m-r-5"></i> ¿Has olvidado tu
                          contraseña?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {error && (
                  <div className="form-group text-center">
                    <div className="col-xs-12">
                      <Alert>{error}</Alert>
                    </div>
                  </div>
                )}
                <div className="form-group text-center">
                  {buttonActive && (
                    <div className="col-xs-12 p-b-20">
                      <button
                        className="btn btn-block btn-lg btn-onpole btn-rounded"
                        type="submit"
                      >
                        Iniciar Sesión
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}
