export const countries = [
  { key: "ESP", value: "España", isoCode: "es" },
  { key: "AUT", value: "Austria", isoCode: "at" },
  { key: "BEL", value: "Bélgica", isoCode: "be" },
  { key: "BGR", value: "Bulgaria", isoCode: "bg" },
  { key: "CYP", value: "Chipre", isoCode: "cy" },
  { key: "CZE", value: "República Checa", isoCode: "cz" },
  { key: "DEU", value: "Alemania", isoCode: "de" },
  { key: "DNK", value: "Dinamarca", isoCode: "dk" },
  { key: "EST", value: "Estonia", isoCode: "ee" },
  { key: "FIN", value: "Finlandia", isoCode: "fi" },
  { key: "FRA", value: "Francia", isoCode: "fr" },
  { key: "GRC", value: "Grecia", isoCode: "gr" },
  { key: "HRV", value: "Croacia", isoCode: "hr" },
  { key: "HUN", value: "Hungría", isoCode: "hu" },
  { key: "IRL", value: "Irlanda", isoCode: "ie" },
  { key: "ITA", value: "Italia", isoCode: "it" },
  { key: "LTU", value: "Lituania", isoCode: "lt" },
  { key: "LUX", value: "Luxemburgo", isoCode: "lu" },
  { key: "LVA", value: "Letonia", isoCode: "lv" },
  { key: "MLT", value: "Malta", isoCode: "mt" },
  { key: "NLD", value: "Países Bajos", isoCode: "nl" },
  { key: "POL", value: "Polonia", isoCode: "pl" },
  { key: "PRT", value: "Portugal", isoCode: "pt" },
  { key: "ROU", value: "Rumanía", isoCode: "ro" },
  { key: "SWE", value: "Suecia", isoCode: "se" },
  { key: "SVN", value: "Eslovenia", isoCode: "si" },
  { key: "SVK", value: "Eslovaquia", isoCode: "sk" },
  { key: "GBR", value: "Reino Unido", isoCode: "gb" },
  { key: "ARG", value: "Argentina", isoCode: "ar" },
  { key: "BOL", value: "Bolivia", isoCode: "bo" },
  { key: "BRA", value: "Brasil", isoCode: "br" },
  { key: "CAN", value: "Canadá", isoCode: "ca" },
  { key: "CHL", value: "Chile", isoCode: "cl" },
  { key: "COL", value: "Colombia", isoCode: "co" },
  { key: "CRI", value: "Costa Rica", isoCode: "cr" },
  { key: "CUB", value: "Cuba", isoCode: "cu" },
  { key: "DOM", value: "República Dominicana", isoCode: "do" },
  { key: "ECU", value: "Ecuador", isoCode: "ec" },
  { key: "SLV", value: "El Salvador", isoCode: "sv" },
  { key: "GTM", value: "Guatemala", isoCode: "gt" },
  { key: "HND", value: "Honduras", isoCode: "hn" },
  { key: "MEX", value: "México", isoCode: "mx" },
  { key: "NIC", value: "Nicaragua", isoCode: "ni" },
  { key: "PAN", value: "Panamá", isoCode: "pa" },
  { key: "PRY", value: "Paraguay", isoCode: "py" },
  { key: "PER", value: "Perú", isoCode: "pe" },
  { key: "PRI", value: "Puerto Rico", isoCode: "pr" },
  { key: "USA", value: "Estados Unidos", isoCode: "us" },
  { key: "URY", value: "Uruguay", isoCode: "uy" },
  { key: "VEN", value: "Venezuela", isoCode: "ve" },
];

export function convertIsoCountryCode(code: string): string {
  const f = countries.find((c) => c.key === code)
  if (f) {
    return f.isoCode
  }

  return code
}
