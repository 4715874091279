import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import {Badge, Button, Form, InputGroup, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import ERoutes from "../../core/enums/ERoutes";
import hasPermission from "../../core/utils/hasPermission";
import Paginator from "../../shared/common/Paginator";
import findFastLapRequest from "../../core/api/requests/utils/findFastLapRequest";
import findJourneysRequest from "../../core/api/requests/utils/findJourneysRequest";
import IFastLap from "../../core/interfaces/IFastLap";
import IJourney from "../../core/interfaces/IJourney";
import moment from "moment";
import SeeFastLapModal from "../../shared/modals/SeeFastLapModal";
import SeeJourneyModal from "../../shared/modals/SeeJourneyModal";
import translator from "../../core/utils/translator";

const permission: IPermission = {
  module: EModules.CIRCUITS,
  method: ECommonPermissions.FIND,
}

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ fastLaps, setFastLaps ] = useState<IFastLap[]>([]);
  const [ journeys, setJourneys ] = useState<IJourney[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(15);
  const [ query, setQuery ] = useState<string>('');

  const [ selectedJourney, setSelectedJourney ] = useState<IJourney>()
  const [ selectedFastLap, setSelectedFastLap ] = useState<IFastLap>()


  const changePageAndFind = (page: number) => {
    find(page);
  }

  const find = async (pageByParam?: number) => {
    if (pageByParam) setPage(pageByParam)

    const responseFastLaps = await findFastLapRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
    )

    const responseJourneys = await findJourneysRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
    )

    if (responseFastLaps?.data) {
      setJourneys(responseJourneys.data?.data || [])
      setFastLaps(responseFastLaps.data?.data || [])
      setCount((responseFastLaps.data?.count || 0) + (responseJourneys.data?.count || 0))

      setPage(responseFastLaps.data?.pagination?.page || 1)
      setPerPage(responseFastLaps.data?.pagination?.perPage || 15)
      setTotalPages((responseFastLaps.data?.pagination?.total > responseJourneys.data?.pagination?.total ? responseFastLaps.data?.pagination?.total : responseJourneys.data?.pagination?.total) || 1)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [query])

  const closeAll = () => {
    setSelectedFastLap(undefined)
    setSelectedJourney(undefined)

    find()
  }

  return <SecureView permission={permission}>
    <SeeFastLapModal
      onClose={closeAll}
      show={!!selectedFastLap}
      fastLap={selectedFastLap}
    />

    <SeeJourneyModal
      onClose={closeAll}
      show={!!selectedJourney}
      journeyId={selectedJourney?.id}
    />

    <Row>
      <Card className={'col-12'}>
        <CardBody title={`Verificaciones ${count}`}>
          <div className="table-responsive">
            <table className="table table-bordered m-t-30 table-hover contact-list">
              <thead>
              <tr>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              {fastLaps.map((fastLap: IFastLap) => {
                return <tr key={fastLap.id}>
                  <td>{moment(fastLap.updateContext.date || fastLap.date).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>
                    <Badge bg={'primary text-white'}>Vueltas rápidas</Badge>
                  </td>
                  <td>{translator(fastLap.status)}</td>
                  <td>
                    <Button size={'sm'} variant={'secondary'} onClick={() => setSelectedFastLap(fastLap)}>
                      <i className={'ti-eye'}></i>
                    </Button>
                  </td>
                </tr>
              })
              }
              {journeys.map((journey: IJourney) => {
                return <tr key={journey.id}>
                  <td>{moment(journey.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>
                    <Badge bg={'info'}>Jornadas</Badge>
                  </td>
                  <td>{translator(journey.status)}</td>
                  <td>
                    <Button size={'sm'} variant={'secondary'} onClick={() => setSelectedJourney(journey)}>
                      <i className={'ti-eye'}></i>
                    </Button>
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>

            <Paginator
              total={totalPages}
              page={page}
              goTo={changePageAndFind}
            />
          </div>


        </CardBody>
      </Card>
    </Row>
  </SecureView>
}
