import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sweetalert2 from "sweetalert2";
import forcePasswordChangeRequest, {
  IForcePasswordChangeRequest,
} from "../../../core/api/requests/auth/forcePasswordChangeRequest";
import getMyProfileRequest from "../../../core/api/requests/me/getMyProfileRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import ERoutes from "../../../core/enums/ERoutes";
import Body from "../../../shared/common/Body";

export default function ForcePasswordChange() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const getMyProfile = async () => await getMyProfileRequest(dispatch);
  const { search } = useLocation();

  const [password, setPassword] = useState<string>();
  const [passwordRepeat, setPasswordRepeat] = useState<string>();

  const [error, setError] = useState<string>("");

  const request = async (e: any) => {
    e.preventDefault();
    if (!password) return;

    const data: IForcePasswordChangeRequest = {
      password,
    };

    const response = await forcePasswordChangeRequest(dispatch, data);

    await getMyProfile();
    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(`Excelente`, `clave modificada exitosamente.`, "success")
        .then(() => navigator(ERoutes.DASHBOARD));
    } else {
      sweetalert2.fire(`Error`, response.data.message, "error");
    }
  };

  const setAndValidatePassword = (value: string) => {
    setPassword(value);

    if (value.length < 8) {
      setError("la clave debe contener mas de 8 caracteres");
      return;
    }

    if (value !== passwordRepeat) {
      setError("Las claves deben coincidir");
      return;
    }

    setError("");
    return;
  };

  const setAndValidateRepeatPassword = (value: string) => {
    setPasswordRepeat(value);

    if (value !== password) {
      setError("Las claves deben coincidir");
      return;
    }

    setError("");
    return;
  };

  useEffect(() => {
    const initPage = async () => {
      const user = await getMyProfile();
      if (!user.data.user.firstInit) navigator(ERoutes.DASHBOARD);
    };
    initPage();
  }, []);

  return (
    <Body>
      <section id="wrapper" className={"fullWidth"}>
        <div className="login-register login-picture">
          <div className="login-box card">
            <div className="card-body">
              <form
                className="form-horizontal form-material"
                onSubmit={request}
              >
                <h3 className="text-center m-b-20">Cambia tu contraseña</h3>

                <div className="form-group ">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="password"
                      required={true}
                      placeholder="Contraseña"
                      value={password}
                      onChange={(e) => setAndValidatePassword(e.target?.value)}
                    />
                  </div>
                </div>

                <div className="form-group ">
                  <div className="col-xs-12">
                    <input
                      className="form-control"
                      type="password"
                      required={true}
                      placeholder="Repetir contraseña"
                      value={passwordRepeat}
                      onChange={(e) =>
                        setAndValidateRepeatPassword(e.target?.value)
                      }
                    />
                  </div>
                </div>

                {error && (
                  <div className="form-group text-center">
                    <div className="col-xs-12">
                      <Alert>{error}</Alert>
                    </div>
                  </div>
                )}

                {!error && (
                  <div className="form-group text-center">
                    <div className="col-xs-12 p-b-20">
                      <button
                        className="btn btn-block btn-lg btn-onpole btn-rounded"
                        type="submit"
                      >
                        Cambiar contraseña
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </Body>
  );
}
