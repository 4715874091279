import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {setCircuits} from "../../../store/slices/availableCircuitsSlice";

export default async function getMyCircuitsRequest(
  dispatch: Dispatch,
): Promise<IResponse> {
  const request = new RACARequest('me', 'myCircuits')

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );


  if (response.data) {
    dispatch(
      setCircuits(response.data)
    );
  }

  return response;
}
