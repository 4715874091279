import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import translator from "../../core/utils/translator";
import ICircuit from "../../core/interfaces/ICircuit";

export interface IInput {
  onClose(): void;
  show: boolean;
  circuit: ICircuit;
  onSave?(data: any): void;
}

export default function (data: IInput) {
  const dispatch = useDispatch()

  const [ day, setDay ] = useState<string>('')

  const [ hourBlocks, setHourBlocks ] = useState<any[]>([])

  const [ operationHours, setOperationHours ] = useState<any>()

  const [ selectedHour, setSelectedHour ] = useState<number>(-1)

  const [ fromHour, setFromHour ] = useState<number>(0)
  const [ fromMin, setFromMin ] = useState<number>(0)
  const [ toHour, setToHour ] = useState<number>(0)
  const [ toMin, setToMin ] = useState<number>(0)

  useEffect(() => {
    if (data?.circuit?.operationHours) {
      setOperationHours({
        ...data.circuit.operationHours
      })
    }
  }, [data.show]);

  const saveDay = (): any => {
    if (!operationHours) return;

    const oh = {...operationHours}

    if (hourBlocks && day) {
      oh[day] = hourBlocks
    }

    setOperationHours({...oh})

    return oh
  }

  const onSelectDay = (newDay: string) => {
    saveDay()
    if (!operationHours) return;

    const hb = operationHours[newDay]
    if (!hb) return

    setHourBlocks([...hb])

    setDay(newDay)
  }

  const create = () => {
    const hbTemp = [...hourBlocks]

    hbTemp.push({
      start: `${fromHour.toString().padStart(2, "0")}:${fromMin.toString().padStart(2, "0")}`,
      end: `${toHour.toString().padStart(2, "0")}:${toMin.toString().padStart(2, "0")}`,
      month: []
    })
    setHourBlocks([
      ...hbTemp,
    ])
    reset()
  }

  const save = () => {
    const hbTemp = [...hourBlocks]

    hbTemp[selectedHour].start = `${fromHour.toString().padStart(2, "0")}:${fromMin.toString().padStart(2, "0")}`
    hbTemp[selectedHour].end = `${toHour.toString().padStart(2, "0")}:${toMin.toString().padStart(2, "0")}`

    setHourBlocks([
      ...hbTemp,
    ])
    reset()
  }

  const saveAll = () => {
    if (selectedHour !== -1) {
      save()
    }

    const ohTemp = saveDay()

    if (data.onSave) {
      data.onSave(ohTemp)
    }
  }

  const remove = () => {
    const hbTemp = [...hourBlocks]

    hbTemp.splice(selectedHour, 1)

    setHourBlocks([
      ...hbTemp,
    ])
    reset()
  }

  const reset = () => {
    setToHour(0)
    setToMin(0)
    setFromHour(0)
    setFromMin(0)
    setSelectedHour(-1)
  }

  return <Modal onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton>
      <Modal.Title>Modificar horarios</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Row>
        <Col>
          <Form.Group className="mb-3 col-12">
            <Form.Label>Seleccione día</Form.Label>
            <Form.Select value={day} className={'col-12'} onChange={(e) => onSelectDay(e.target.value)}>
              <option key={-1} value={-1}>Seleccione un día...</option>
              {(Object.keys(data.circuit.operationHours) || []).map((k) => <option key={k} value={k}>{translator(k)}</option>)}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          {hourBlocks.map((hb, index) => {
            const selected = index === selectedHour

            let fh = fromHour
            let fm = fromMin

            let th = toHour
            let tm = toMin

            if (!selected) {
              const from = (hb.start || ':').split(':')
              const to = (hb.end || ':').split(':')

              if (from.length === 2) {
                fh = parseInt(from[0])
                fm = parseInt(from[1])
              }

              if (to.length === 2) {
                th = parseInt(to[0])
                tm = parseInt(to[1])
              }
            }

            return <article className={'time-selector'}>
              <div className={'time-selector-container'}>
                <select disabled={!selected} value={fh} onChange={(v) => setFromHour(parseInt(v.target.value))}>
                  {new Array(24).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
                <select disabled={!selected} value={fm} onChange={(v) => setFromMin(parseInt(v.target.value))}>
                  {new Array(59).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
              </div>

              <div className={'time-selector-container'}>
                <select disabled={!selected} value={th} onChange={(v) => setToHour(parseInt(v.target.value))}>
                  {new Array(24).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
                <select disabled={!selected} value={tm} onChange={(v) => setToMin(parseInt(v.target.value))}>
                  {new Array(59).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
              </div>

              {selected ?
                <div>
                  <Button variant={'success'} onClick={save} className={'mr-2'}>
                    <i className={'ti-save'}></i>
                  </Button>

                  <Button variant={'danger'} onClick={remove}>
                    <i className={'ti-trash'}></i>
                  </Button>
                </div>
                :
                <Button variant={'warning'} onClick={() => {
                  setFromHour(fh)
                  setFromMin(fm)
                  setToHour(th)
                  setToMin(tm)
                  setSelectedHour(index)
                }}>
                  <i className={'ti-pencil'}></i>
                </Button>
              }


            </article>
          })
          }

          {(selectedHour === -1 && day !== '') &&
            <article className={'time-selector'}>
              <div className={'time-selector-container'}>
                <select value={fromHour} onChange={(v) => setFromHour(parseInt(v.target.value))}>
                  {new Array(24).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
                <select value={fromMin} onChange={(v) => setFromMin(parseInt(v.target.value))}>
                  {new Array(59).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
              </div>

              <div className={'time-selector-container'}>
                <select value={toHour} onChange={(v) => setToHour(parseInt(v.target.value))}>
                  {new Array(24).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
                <select value={toMin} onChange={(v) => setToMin(parseInt(v.target.value))}>
                  {new Array(59).fill(0).map((i, k) => {
                    return <option key={k} value={k}>{k}</option>
                  })
                  }
                </select>
              </div>

              <Button variant={'success'} onClick={create}>
                <i className={'ti-save'}></i>
              </Button>
            </article>
          }
        </Col>
      </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>

      <Button
        variant="success"
        onClick={saveAll}
      >
        Guardar
      </Button>
    </Modal.Footer>
  </Modal>
}
