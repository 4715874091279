import CardBody from "../../shared/lib/CardBody";
import {Badge, Button, Col, Row} from "react-bootstrap";
import translator from "../../core/utils/translator";
import React, {useState} from "react";
import ICircuit from "../../core/interfaces/ICircuit";
import ModifyHoursModal from "../../shared/modals/ModifyHoursModal";
import updateCircuitRequest from "../../core/api/requests/circuits/updateCircuitRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import {useDispatch} from "react-redux";

export interface IInput {
  circuit: ICircuit;
  forceReload?(): void;
}

export default function (input: IInput) {
  const dispatch = useDispatch()
  const [ show, setShow ] = useState<boolean>(false)

  const openModal = () => {
    setShow(true)
  }

  const update = async (data: any) => {
    if (!input.circuit) return

    const basicInformation: any = {
      action: 'basicInformation',
      data: {
        operationHours: data,
      }
    }

    const response = await updateCircuitRequest(dispatch, basicInformation, input.circuit.id)

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Circuito ${input.circuit.name} modificado exitosamente.`,
        'success',
      ).then(() => {
        setShow(false)
      })
    } else {
      sweetalert2.fire(
        `Error`,
        translator(response.data.message),
        'error',
      )
    }

    if (input.forceReload) {
      input.forceReload()
    }
  }

  return <CardBody>
    <ModifyHoursModal
      show={show}
      onClose={() => setShow(false)}
      circuit={input.circuit}
      onSave={update}
    />

    <Row>
      <Col>
        <div className={'time-title-container'}>
          <h4 className={''}>Horarios</h4>

          <Button variant="primary" type="button" onClick={openModal}>
            Modificar
          </Button>
        </div>

        {Object.keys(input.circuit?.operationHours || {}).map((oh) => {
          return <div className={'time-day-container'}>
            <h5>{translator(oh)}</h5>
            <div className={'time-badge-container'}>
              {(input.circuit?.operationHours[oh] || []).map((h: any) => {
                return <Badge className={'time-badge'} bg={'primary'}>{h.start} - {h.end}</Badge>
              })
              }
            </div>
          </div>
        })
        }
      </Col>
    </Row>
  </CardBody>
}
