import IMenuItem from "../../core/interfaces/IMenuItem";
import ERoutes from "../../core/enums/ERoutes";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";

const menuList: IMenuItem[] = [
  {
    name: 'Dashboard',
    icon: 'ti-dashboard',
    forCircuit: false,
    redirectTo: ERoutes.DASHBOARD,
  },
  {
    name: 'Usuarios generales',
    icon: 'ti-user',
    forCircuit: false,
    redirectTo: ERoutes.USERS_LIST,
    permission: {
      module: EModules.USERS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Circuitos',
    icon: 'rc-circuit',
    forCircuit: false,
    redirectTo: ERoutes.CIRCUITS_LIST,
    permission: {
      module: EModules.CIRCUITS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Consejos',
    icon: 'ti-notepad',
    forCircuit: false,
    redirectTo: ERoutes.TIPS_LIST,
    permission: {
      module: EModules.TIPS,
      method: ECommonPermissions.FIND,
    }
  },
  {
    name: 'Denuncias',
    icon: 'ti-help',
    forCircuit: false,
    redirectTo: ERoutes.REPORTS_LIST,
    permission: {
      module: EModules.COMMUNITY,
      method: ECommonPermissions.REMOVE,
    }
  },
  {
    name: 'Verificaciones',
    icon: 'ti-check',
    forCircuit: false,
    redirectTo: ERoutes.VALIDATIONS_LIST,
    permission: {
      module: EModules.COMMUNITY,
      method: ECommonPermissions.REMOVE,
    }
  },
  {
    name: 'Tandas',
    icon: 'ti-shopping-cart',
    forCircuit: true,
    redirectTo: ERoutes.CIRCUIT_PRODUCT_LIST,
  },
  {
    name: 'Ofertas',
    icon: 'rc-offers',
    forCircuit: true,
    redirectTo: ERoutes.CIRCUIT_OFFER_LIST,
  },
  {
    name: 'Carreras',
    icon: 'rc-racing',
    forCircuit: true,
    redirectTo: ERoutes.CIRCUIT_RACING_LIST,
  },
  {
    name: 'Eventos',
    icon: 'rc-events',
    forCircuit: true,
    redirectTo: ERoutes.CIRCUIT_EVENTS_LIST,
  },
  {
    name: 'Mi Circuito',
    icon: 'ti-home',
    forCircuit: true,
    redirectTo: ERoutes.MY_CIRCUIT,
  },
]

export default menuList;
