import {useRef} from "react";

interface IInput {
  uri: string;
  onChange?(file: File): void;
}

export default function (props: IInput) {
  const imgRef = useRef(null)

  const onChangeFile = (e: any) => {
    if (props.onChange && e?.target?.value) {
      props.onChange(e.target.files[0])
    }
  }

  const openChangeFile = () => {
    if (!props.onChange) return
    if (!imgRef || !imgRef.current) return

    // @ts-ignore
    imgRef.current.click();
  }

  return <div className={"profile-image-container"}>
    <img onClick={openChangeFile} src={props.uri} className={"profile-image"} alt={"profile-image"} />

    <input ref={imgRef} className={'d-none'} type={'file'} onChange={onChangeFile} accept={'image/*'} />
  </div>
}
