import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../../Request";
import IBasicFilters from "../../../../interfaces/IBasicFilters";
import requestMiddlewares from "../../../requestMiddlewares";
import IPager from "../../../../interfaces/IPager";
import ICircuitEvent from "../../../../interfaces/ICircuitEvent";

export default async function (
  dispatch: Dispatch,
  filters: IBasicFilters,
  id: string
): Promise<IResponse<IPager<ICircuitEvent>>> {
  dispatch(startLoading());

  const request = new RACARequest('circuits', 'events-list')
    .setPathValues('ID', id)
    .setQueryParameters({
      ...filters,
      circuitId: id,
    });

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
