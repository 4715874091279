import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";

type IInput = {
  title: string;
  content: {
    text: string;
  },
  owner: string;
  type: string;
  level: string;
}

export default async function (
  dispatch: Dispatch,
  data: IInput,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('tips', 'create')
    .setData(data)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
