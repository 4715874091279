import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {RootState} from "../../core/store/store";
import React, {useState} from "react";
import ERoutes from "../../core/enums/ERoutes";
import ICircuit from "../../core/interfaces/ICircuit";
import IAddress from "../../core/interfaces/IAddress";
import ChangeAddressModal from "../../shared/modals/ChangeAddressModal";
import addCircuitRequest from "../../core/api/requests/circuits/addCircuitRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";

const permission: IPermission = {
  module: EModules.CIRCUITS,
  method: ECommonPermissions.CREATE,
}

export default function () {
  const userMe = useSelector((state: RootState) => state.userMe)
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const params = useParams();

  const [ circuit, setCircuit ] = useState<ICircuit>({
    id: '',
    name: '',
    type: '',
    address: {
      latitude: 0,
      longitude: 0,
      fullAddress: '',
      streetNumber: '',
      address: '',
      city: '',
      province: '',
      country: '',
      countryCode: '',
      postalCode: '',
    },
    users: [],
    length: 0,
    phoneNumbers: [],
    createdAt: new Date(),
  })

  const [ modalLocation, setModalLocation ] = useState<boolean>(false)

  const [ error, setError ] = useState<string>('')

  const goBack = () => navigator(ERoutes.CIRCUITS_LIST)

  const create = async () => {
    if (!circuit.name) return

    const response = await addCircuitRequest(
      dispatch,
      {
        name: circuit.name,
        type: circuit.type,
        length: circuit.length || 0,
        address: circuit.address,
        users: [],
      }
    )

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Circuito ${circuit.name} agregado exitosamente.`,
        'success',
      ).then(() => {
        navigator(ERoutes.CIRCUITS_VIEW.replace(':id', response.data.id))
      })
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  const setValue = (
    field: string,
    value: any,
  ) => {
    const c = circuit
    if (!c) return

    switch (field) {
      case 'name':
        c.name = value
        break;
      case 'type':
        c.type = value;
        break;
      case 'length':
        c.length = parseInt(value);
        break;
      default:
        return
    }

    if (c) {
      setCircuit({
        ...c
      });
    }
  }

  const changeLocation = async (address: IAddress) => {
    if (!circuit) return

    setCircuit((prevState: ICircuit) => {
      if (!prevState) return prevState

      return {
        ...prevState,
        address,
      }
    })
  }

  return <SecureView permission={permission}>
    <ChangeAddressModal
      show={modalLocation}
      onSubmit={changeLocation}
      onClose={() => setModalLocation(false)}
    />

    <Card className={'col-12'}>
      <CardBody title={'Información general'}>
        <Row>
          <Form.Group className="mb-3 col-4">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="Circuito 001"
              value={circuit?.name}
              onChange={(e) => setValue('name', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={circuit?.type}
              onChange={(e) => setValue('type', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              type="number"
              placeholder="1000"
              value={circuit?.length}
              onChange={(e) => setValue('length', e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>Dirección</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Gran vía 123"
                value={circuit?.address.address}
                disabled
              />
              <Button onClick={() => setModalLocation(!modalLocation)} variant="primary" id="button-addon2">
                <i className={'ti-pencil'}></i>
              </Button>
            </InputGroup>
          </Form.Group>


          <Form.Group className="mb-3 col-2">
            <Form.Label>Latitud</Form.Label>
            <Form.Control
              type="number"
              value={circuit?.address.latitude}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              type="number"
              value={circuit?.address.longitude}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Código postal</Form.Label>
            <Form.Control
              type="number"
              value={circuit?.address.postalCode}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              value={circuit?.address.city}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Provincia</Form.Label>
            <Form.Control
              type="text"
              value={circuit?.address.province}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>País</Form.Label>
            <Form.Control
              type="text"
              value={circuit?.address.country}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Código de País</Form.Label>
            <Form.Control
              type="text"
              value={circuit?.address.countryCode}
              disabled
            />
          </Form.Group>
        </Row>
      </CardBody>

      <CardBody>
        <Button variant="info" type="button" onClick={create} className={'mt-3'}>
          <i className='ti-save'></i> Crear
        </Button>

        <Button variant="secondary" type="button" onClick={goBack} className={'mt-3 ml-2'}>
          Cancelar
        </Button>

      </CardBody>
    </Card>

  </SecureView>
}
