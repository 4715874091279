import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import sweetalert2 from "sweetalert2";
import createTipRequest from "../../core/api/requests/tips/createTipRequest";
import levels from "../../core/utils/levels";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import createOfferRequest from "../../core/api/requests/circuits/offers/createOfferRequest";
import {RootState} from "../../core/store/store";

type IInput = {
  onClose(): void;
  show: boolean;
}

export default function (data: IInput) {
  const dispatch = useDispatch()
  const selectedCircuit = useSelector((state: RootState) => state.selectedCompany)

  const [ content, setContent ] = useState<string>('')
  const [ title, setTitle ] = useState<string>('')

  const save = async () => {
    if (!selectedCircuit?.circuit) return

    const response = await createOfferRequest(
      dispatch,
      {
        name: title,
        description: content,
      },
      selectedCircuit.circuit.id,
    )

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Oferta creada exitosamente.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else {
      sweetalert2.fire(
        `Error`,
        'Error al crear la oferta, debes introducir un título',
        'error',
      )
    }

    setTitle('')
    setContent('')
  }

  return <Modal onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton>
      <Modal.Title>Crear oferta</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Form.Group className="mb-3 col-12">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            placeholder={'Ejemplo: Día del Piloto los Viernes: 15€/tanda.'}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Form.Group>
      </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={save}
      >
        Crear
      </Button>
    </Modal.Footer>
  </Modal>
}
