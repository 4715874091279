import moment from "moment";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import createEventRequest from "../../core/api/requests/circuits/events/createEventRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import { RootState } from "../../core/store/store";
import translator from "../../core/utils/translator";

type IInput = {
	onClose(): void;
	show: boolean;
};

export default function (data: IInput) {
	const dispatch = useDispatch();
	const selectedCircuit = useSelector(
		(state: RootState) => state.selectedCompany
	);

	const [name, setName] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const [date, setDate] = useState<Date>(new Date());
	const [dateEnd, setDateEnd] = useState<Date>(new Date());
	const [price, setPrice] = useState<number>(0);
	const [maxUser, setMaxUser] = useState<number>(0);
	const [kart, setKart] = useState<string>("");
	const [image, setImage] = useState<string>("");

	const [karts, setKarts] = useState<string[]>([]);

	useEffect(() => {
		if (data.show) {
			if (
				selectedCircuit?.circuit?.kartingTypes &&
				selectedCircuit?.circuit?.kartingTypes?.length > 0
			) {
				setKart(selectedCircuit.circuit.kartingTypes[0]);
			}
			setKarts(selectedCircuit?.circuit?.kartingTypes || []);
		}
	}, [data.show]);

	const save = async () => {
		if (!selectedCircuit.circuit) return;

		console.log(name, description, date, kart);

		if (!name || !description || !date || !kart) {
			sweetalert2.fire(
				`Atención`,
				`Debes completar todos los campos para continuar.`,
				"error"
			);
			return;
		}

		const response = await createEventRequest(dispatch, {
			name,
			circuitId: selectedCircuit.circuit.id,
			startDate: date,
			endDate: dateEnd,
			description,
			kart,
			price,
			maxUser,
		});

		if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
			sweetalert2
				.fire(`Excelente`, `Evento creado exitosamente.`, "success")
				.then(() => {
					data.onClose();
				});
		} else {
			sweetalert2.fire(`Error`, translator(response.data.message), "error");
		}

		setName("");
		setKart("");
		setDescription("");
		setDate(new Date());
		setDateEnd(new Date());
		setPrice(0);
	};

	return (
		<Modal onHide={data.onClose} show={data.show}>
			<Modal.Header closeButton>
				<Modal.Title>Crear Evento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Alert variant={"info"}>
					Recuerda que puedes cambiar la imagen del evento una vez creado,
					presionando en el botón ver.
				</Alert>

				<Row>
					<Form.Group className="mb-3 col-12">
						<Form.Label>Fecha</Form.Label>
						<Form.Control
							type="datetime-local"
							value={moment(date).format("YYYY-MM-DD HH:mm:ss")}
							onChange={(e) => setDate(new Date(e.target.value))}
						/>
					</Form.Group>

					{/*<Form.Group className="mb-3 col-12">*/}
					{/*  <Form.Label>Fecha Finalización</Form.Label>*/}
					{/*  <Form.Control*/}
					{/*    type="datetime-local"*/}
					{/*    value={moment(dateEnd).format('YYYY-MM-DD HH:mm:ss')}*/}
					{/*    onChange={(e) => setDateEnd(new Date(e.target.value))}*/}
					{/*  />*/}
					{/*</Form.Group>*/}

					<Form.Group className="mb-3 col-12">
						<Form.Label>Nombre</Form.Label>
						<Form.Control
							type="text"
							placeholder={"Carrera de Resistencia de 1h"}
							onChange={(e) =>
								e.target.value?.length < 33 ? setName(e.target.value) : () => {}
							}
							value={name}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Kart</Form.Label>
						<Form.Select
							value={kart}
							className={"col-12"}
							onChange={(e) => setKart(e.target.value)}
						>
							{karts.map((k) => (
								<option key={k} value={k}>
									{translator(k)}
								</option>
							))}
						</Form.Select>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Precio (€)</Form.Label>
						<Form.Control
							type="number"
							onChange={(e) => setPrice(parseFloat(e.target.value || "0"))}
							value={price}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Descripción</Form.Label>
						<Form.Control
							type="text"
							placeholder={
								"Entrenamientos cronometrados de 10 minutos + Carrera de 50 minutos."
							}
							onChange={(e) => setDescription(e.target.value)}
							value={description}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Inscripciones maximas</Form.Label>
						<Form.Control
							type="number"
							onChange={(e) =>
								setMaxUser(Math.round(Number(e.target.value) || 0))
							}
							value={maxUser}
						/>
					</Form.Group>
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={data.onClose}>
					Cancelar
				</Button>

				<Button variant="success" onClick={save}>
					Crear
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
