import { useEffect, useState } from "react";
import { Badge, Button, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import findUsersRequest from "../../core/api/requests/users/findUsersRequest";
import createUserStripeLinkRequest from "../../core/api/requests/users/generateStripeLinkRequest";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EModules from "../../core/enums/EModules";
import ERoutes from "../../core/enums/ERoutes";
import IPermission from "../../core/interfaces/IPermission";
import IUser from "../../core/interfaces/IUser";
import { RootState } from "../../core/store/store";
import hasPermission from "../../core/utils/hasPermission";
import translator from "../../core/utils/translator";
import Paginator from "../../shared/common/Paginator";
import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";

const permission: IPermission = {
  module: EModules.USERS,
  method: ECommonPermissions.FIND,
};

export default function UsersList() {
  const permissions = useSelector((state: RootState) => state.permissions);
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [users, setUsers] = useState<IUser[]>([]);

  const [count, setCount] = useState<number>(0);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(30);
  const [query, setQuery] = useState<string>("");

  const changePageAndFind = (page: number) => {
    find(page);
  };

  const find = async (pageByParam?: number) => {
    if (pageByParam) setPage(pageByParam);

    const response = await findUsersRequest(dispatch, {
      page: pageByParam || page,
      perPage,
      query,
    });

    if (response?.data) {
      setUsers(response.data?.data || []);
      setCount(response.data?.count || 0);

      setPage(response.data?.pagination?.page || 1);
      setPerPage(response.data?.pagination?.perPage || 1);
      setTotalPages(response.data?.pagination?.total || 1);
    }
  };

  useEffect(() => {
    find();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <SecureView permission={permission}>
      <Row>
        <Card className={"col-12"}>
          <CardBody title={`Usuarios ${count}`}>
            <div className={"row mt-5"}>
              <div className={"col-9"}>
                <InputGroup>
                  <Form.Control
                    placeholder="Buscar por nombre o email"
                    aria-label="Buscar por nombre o email"
                    aria-describedby="Buscar por nombre o email"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={(key) => key.key === "Enter" && find(1)}
                  />
                  <Button
                    variant="outline-primary"
                    id="button-addon2"
                    onClick={() => find(1)}
                  >
                    <i className={"ti-search"}></i> Buscar
                  </Button>
                </InputGroup>
              </div>

              <div className={"col-3"}>
                <Link
                  to={ERoutes.USERS_CREATE}
                  className="btn btn-info float-right"
                >
                  Nuevo Usuario
                </Link>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered m-t-30 table-hover contact-list">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nombre</th>
                    <th>Nombre Usuario</th>
                    <th>Email</th>
                    <th>Estado</th>
                    <th>Rol</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => {
                    return (
                      <tr key={user.id}>
                        <td>{user.id}</td>
                        <td>
                          {user.name} {user.lastName}
                        </td>
                        <td>{user.userName}</td>
                        <td>{user.email}</td>
                        <td>
                          {user.status === "active" ? (
                            <Badge text={"white"} bg={"success"}>
                              Activo
                            </Badge>
                          ) : (
                            <Badge text={"white"} bg={"warning"}>
                              {translator(user.status)}
                            </Badge>
                          )}
                        </td>
                        <td>{user.role.name}</td>
                        <td>
                          {hasPermission(permissions, {
                            module: permission.module,
                            method: ECommonPermissions.FIND_BY_ID,
                          }) && (
                            <Button
                              size={"sm"}
                              variant={"secondary"}
                              style={{ marginRight: 10 }}
                              onClick={() =>
                                navigator(
                                  ERoutes.USERS_VIEW.replace(":id", user.id)
                                )
                              }
                            >
                              <i className={"ti-eye"}></i> Ver
                            </Button>
                          )}
                          {hasPermission(permissions, {
                            module: permission.module,
                            method: ECommonPermissions.FIND_BY_ID,
                          }) &&
                            !user.stripeAccount?.id && (
                              <Button
                                size={"sm"}
                                variant={"secondary"}
                                onClick={async () => {
                                  await createUserStripeLinkRequest(dispatch, {
                                    email: user.email,
                                  });
                                  find();
                                }}
                              >
                                <i className={"ti-money"}></i> Connect Stripe
                              </Button>
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Paginator
                total={totalPages}
                page={page}
                goTo={changePageAndFind}
              />
            </div>
          </CardBody>
        </Card>
      </Row>
    </SecureView>
  );
}
