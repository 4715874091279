import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import findEventRequest from "../../core/api/requests/circuits/events/findEventRequest";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import EModules from "../../core/enums/EModules";
import ICircuitEvent from "../../core/interfaces/ICircuitEvent";
import IPermission from "../../core/interfaces/IPermission";
import { RootState } from "../../core/store/store";
import Paginator from "../../shared/common/Paginator";
import SecureView from "../../shared/common/SecureView";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import CreateCircuitEventModal from "../../shared/modals/CreateCircuitEventModal";
import EditCircuitEventModal from "../../shared/modals/EditCircuitEventModal";
import SeeCircuitEventModal from "../../shared/modals/SeeCircuitEventModal";

const permission: IPermission = {
  module: EModules.CIRCUIT_EVENTS,
  method: ECommonPermissions.FIND,
};

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions);
  const selectedCircuit = useSelector(
    (state: RootState) => state.selectedCompany
  );
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [events, setEvents] = useState<ICircuitEvent[]>([]);

  const [count, setCount] = useState<number>(0);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(30);
  const [query, setQuery] = useState<string>("");

  const [createModal, setCreateModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<string>("");
  const [seeModal, setSeeModal] = useState<string>("");

  const changePageAndFind = (page: number) => {
    find(page);
  };

  const find = async (pageByParam?: number) => {
    if (!selectedCircuit.circuit) return;

    if (pageByParam) setPage(pageByParam);

    const response = await findEventRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCircuit.circuit.id
    );

    if (response?.data) {
      setEvents(response.data?.data || []);
      setCount(response.data?.count || 0);

      setPage(response.data?.pagination?.page || 1);
      setPerPage(response.data?.pagination?.perPage || 30);
      setTotalPages(response.data?.pagination?.total || 1);
    }
  };

  useEffect(() => {
    find();
  }, []);

  useEffect(() => {
    find();
  }, [seeModal, createModal, selectedCircuit.circuit]);

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <SecureView permission={permission}>
      <SeeCircuitEventModal
        show={!!seeModal}
        id={seeModal}
        onClose={() => setSeeModal("")}
      />

      <CreateCircuitEventModal
        show={createModal}
        onClose={() => setCreateModal(false)}
      />

      <EditCircuitEventModal
        show={!!editModal}
        id={editModal}
        onClose={() => setEditModal("")}
      />

      <Row>
        <Card className={"col-12"}>
          <CardBody title={`Eventos ${count}`}>
            <div className={"offset-9 col-3"}>
              <Button
                onClick={() => setCreateModal(true)}
                className="btn btn-info float-right"
              >
                Nuevo Evento
              </Button>
            </div>

            <div className="table-responsive">
              <table className="table table-bordered m-t-30 table-hover contact-list">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Fecha</th>
                    <th>Precio</th>
                    <th>Kart</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((e) => {
                    return (
                      <tr key={e.id}>
                        <td>{e.name}</td>
                        <td>
                          {moment(e.startDate).format("DD/MM/YYYY HH:mm")}
                        </td>
                        <td>{e.price} €</td>
                        <td>{e.kart}</td>
                        <td>
                          <Button
                            size={"sm"}
                            variant={"secondary"}
                            onClick={() => setSeeModal(e.id)}
                          >
                            <i className={"ti-eye"}></i> Ver
                          </Button>
                          <Button
                            style={{ marginLeft: 5 }}
                            size={"sm"}
                            variant={"secondary"}
                            onClick={() => setEditModal(e.id)}
                          >
                            <i className={"ti-pencil"}></i> Editar
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Paginator
                total={totalPages}
                page={page}
                goTo={changePageAndFind}
              />
            </div>
          </CardBody>
        </Card>
      </Row>
    </SecureView>
  );
}
