import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import findByIdProductRequest from "../../core/api/requests/circuits/products/findByIdProductRequest";
import updateProductRequest from "../../core/api/requests/circuits/products/updateProductRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import ICircuitProduct from "../../core/interfaces/ICircuitProduct";
import { RootState } from "../../core/store/store";

type IInput = {
  onClose(): void;
  show: boolean;
  id: string;
};

export default function (data: IInput) {
  const dispatch = useDispatch();
  const selectedCircuit = useSelector(
    (state: RootState) => state.selectedCompany
  );

  const [product, setProduct] = useState<ICircuitProduct>();

  const get = async () => {
    if (!data.id || !selectedCircuit.circuit) return;

    const response = await findByIdProductRequest(
      dispatch,
      selectedCircuit.circuit.id,
      data.id
    );

    if (response) {
      setProduct(response.data);
    }
  };

  const removeRequest = async () => {
    if (!data.id || !selectedCircuit.circuit) return;

    const d: any = {
      action: "basicInformation",
      data: {
        status: "inactive",
      },
    };

    const response = await updateProductRequest(
      dispatch,
      d,
      selectedCircuit.circuit.id,
      data.id
    );

    if (response && response.statusCode === 202) {
      sweetalert2
        .fire(`Excelente`, `Producto eliminado exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, "Error al eliminar", "error");
    }
  };

  const remove = async () => {
    sweetalert2
      .fire({
        title: "Atención!",
        text: `¿Seguro que desea eliminar este producto?`,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        icon: "question",
      })
      .then((o) => {
        if (o.isConfirmed) removeRequest();
      });
  };

  const save = async () => {
    if (!selectedCircuit.circuit || !product?.id) return;

    const d: any = {
      action: "basicInformation",
      data: {
        duration: product?.duration,
        name: product?.name,
        price: product?.price,
        description: product?.description,
      },
    };

    const response = await updateProductRequest(
      dispatch,
      d,
      selectedCircuit.circuit.id,
      product.id
    );

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(`Excelente`, `Producto modificado exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, response.data.message, "error");
    }
  };

  const setValue = (field: string, value: any) => {
    const p = product;
    if (!p) return;

    switch (field) {
      case "name":
        p.name = value;
        break;
      case "price":
        p.price = parseFloat(value);
        break;
      case "duration":
        p.duration = parseInt(value);
        break;
      case "description":
        p.description = value;
        break;
      default:
        return;
    }

    if (p) {
      setProduct({
        ...product,
        ...p,
      });
    }
  };

  useEffect(() => {
    get();
  }, [data.id]);

  if (!product) return <div></div>;
  return (
    <Modal size={"xl"} onHide={data.onClose} show={data.show}>
      <Modal.Header closeButton>
        <Modal.Title>Administrar Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>ID</Form.Label>
            <Form.Control type="text" disabled value={product?.id} />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={moment(product.createdAt).format("DD/MM/YYYY HH:mm:ss")}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Circuito ID</Form.Label>
            <Form.Control type="text" disabled value={product?.circuit.id} />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Nombre Circuito</Form.Label>
            <Form.Control type="text" disabled value={product?.circuit.name} />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setValue("name", e.target.value)}
              value={product?.name}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Precio ({product.currency.symbol})</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setValue("price", e.target.value)}
              value={product?.price}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Duración de una tanda (s)</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setValue("duration", e.target.value)}
              value={product?.duration}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Número de tandas</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setValue("description", e.target.value)}
              value={product?.description}
            />
          </Form.Group>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={data.onClose}>
          Cancelar
        </Button>

        <Button variant="danger" onClick={remove}>
          Eliminar
        </Button>
        <Button variant="success" onClick={save}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
