enum EModules {
  USERS = 'users',
  ROLES = 'roles',
  PERMISSIONS = 'permissions',
  SESSIONS = 'sessions',
  NOTIFICATIONS = 'notifications',

  CIRCUITS = 'circuits',
  CIRCUIT_REVIEWS = 'circuitReviews',
  CIRCUIT_PRODUCTS = 'circuitProducts',
  CIRCUIT_BOOKINGS = 'circuitBookings',
  CIRCUIT_HOUR_BLOCKS = 'circuitHourBlocks',
  CIRCUIT_RACING = 'circuitRacing',
  CIRCUIT_EVENTS = 'circuitEvents',
  RACING = 'racing',
  MESSAGES = 'messages',
  COMMUNITY = 'community',
  LEAGUES = 'leagues',
  TIPS = 'tips',
  FAST_LAPS = 'fastLaps',
}

export default EModules;
