import { Dispatch } from "redux";
import { endLoading, startLoading } from "../../../store/slices/loadingSlice";
import { IResponse, RACARequest } from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";

export interface ICreateUserStripeLinkRequest {
  email: string;
}

export default async function createUserStripeLinkRequest(
  dispatch: Dispatch,
  input: ICreateUserStripeLinkRequest
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest("users", "stripe-link").setData(input);

  const response = await request.makeRequest();
  requestMiddlewares(dispatch, response);

  dispatch(endLoading());

  return response;
}
