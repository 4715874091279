import SecureView from '../../shared/common/SecureView';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Col, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import Badge from "../utils/Badge";
import {useEffect, useState} from "react";
import ICircuitMetrics from "../../core/interfaces/ICircuitMetrics";
import getCircuitMetricsRequest from "../../core/api/requests/circuits/metrics/getCircuitMetricsRequest";
import { LineChart } from '@mui/x-charts/LineChart';
import moment from "moment";

export default function Dashboard() {
  const dispatch = useDispatch()
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany);
  const user = useSelector((state: RootState) => state.userMe);
  const futils = useSelector((state: RootState) => state.frontendUtilities);

  const [ metrics, setMetrics ] = useState<ICircuitMetrics>()

  const getMetrics = async () => {
    if (!selectedCompany?.circuit) return
    const response = await getCircuitMetricsRequest(
      dispatch,
      selectedCompany.circuit.id,
    )

    console.log(response)

    if (response.data) {
      setMetrics(response.data)
    }
  }

  useEffect(() => {
    getMetrics()
  }, [selectedCompany]);

  if (!selectedCompany.circuit || !metrics) return <SecureView><div></div></SecureView>
  return <SecureView>
      <Card className={'col-12'}>
        <CardBody title={selectedCompany.circuit.name}>
          <Row>
            <Col sm={4}>
              <Badge
                name={'Visitas'}
                variant={'unknown'}
                value={metrics.visits || 0}
                now={100}
              />
            </Col>

            <Col sm={4}>
              <Badge
                name={'Visitas a eventos'}
                variant={'unknown'}
                value={metrics.events.visits || 0}
                now={100}
              />
            </Col>

            <Col sm={4}>
              <Badge
                name={'Inscripciones a eventos'}
                variant={'unknown'}
                value={metrics.events.inscriptions || 0}
                now={100}
              />
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col sm={4}>
              <Badge
                name={'Tasa de conversión visitas a ventas'}
                variant={'unknown'}
                value={`${(((metrics.events.inscriptions || 0) / (metrics.events.visits || 0)) * 100).toFixed(2)}%`}
                now={100}
              />
            </Col>

            <Col sm={2}>
              <Badge
                name={'Reseñas'}
                variant={'unknown'}
                value={metrics.reviews?.total || 0}
                now={100}
              />
            </Col>

            <Col sm={2}>
              <Badge
                name={'Puntuación'}
                variant={(metrics.reviews?.average || 0) > 50 ? 'success' : 'warning'}
                value={metrics.reviews?.average.toFixed(1) || 0}
                now={100}
              />
            </Col>
            <Col sm={4}>
              <Badge
                name={'Redirecciones a web'}
                value={metrics.buttons?.web || 0}
                variant={'unknown'}
                now={100}
              />
            </Col>
          </Row>

          <Row className={'mt-3'}>
            <Col sm={6}>
              <div className={'line-chart-container'}>
                <h4>Visitas</h4>
                <LineChart
                  xAxis={[{
                    data: metrics.visitsChart?.map((v) => v.date),
                    scaleType: 'band',
                  }]}
                  series={[
                    {
                      data: metrics.visitsChart?.map((v) => v.value),
                    },
                  ]}
                  colors={["#A6051A"]}
                  className={'line-chart'}
                  height={300}
                />
              </div>
            </Col>
            <Col sm={6}>
              <div className={'line-chart-container'}>
                <h4>Reseñas</h4>
                <LineChart
                  xAxis={[{
                    data: metrics.reviewsChart?.map((v) => v.date),
                    scaleType: 'band',
                  }]}
                  series={[
                    {
                      data: metrics.reviewsChart?.map((v) => v.value),
                      area: true,
                    },
                  ]}
                  colors={["#A6051A"]}
                  className={'line-chart'}
                  height={300}
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
  </SecureView>
}
