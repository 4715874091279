import Body from "../../../shared/common/Body";
import {useDispatch} from "react-redux";
import {Link, useLocation, useNavigate, useParams, useRoutes} from "react-router-dom";
import {Alert} from "react-bootstrap";
import ERoutes from "../../../core/enums/ERoutes";
import {useState} from "react";
import passwordRecoveryVerificationRequest, {
  IPasswordRecoveryVerificationRequest
} from "../../../core/api/requests/auth/passwordRecoveryVerificationRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";

export default function PasswordRecoveryVerify() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { search } = useLocation();

  const [ password, setPassword ] = useState<string>();
  const [ passwordRepeat, setPasswordRepeat ] = useState<string>();

  const [ error, setError ] = useState<string>('')

  const request = async (e: any) => {
    e.preventDefault();
    if (!password) return;

    const data: IPasswordRecoveryVerificationRequest = {
      password,
      token: new URLSearchParams(search).get('t') || ''
    }

    const response = await passwordRecoveryVerificationRequest(
      dispatch,
      data,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `clave modificada exitosamente.`,
        'success',
      ).then(() => navigator(ERoutes.AUTH_LOGIN))
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  const setAndValidatePassword = (value: string) => {
    setPassword(value);

    if (value.length < 8) {
      setError('la clave debe contener mas de 8 caracteres')
      return;
    }

    if (value !== passwordRepeat) {
      setError('Las claves deben coincidir')
      return;
    }

    setError('')
    return
  }

  const setAndValidateRepeatPassword = (value: string) => {
    setPasswordRepeat(value);

    if (value !== password) {
      setError('Las claves deben coincidir')
      return;
    }

    setError('')
    return
  }

  return <Body>
    <section id="wrapper" className={"fullWidth"}>
      <div className="login-register login-picture">
        <div className="login-box card">
          <div className="card-body">
            <form className="form-horizontal form-material" onSubmit={request}>
              <h3 className="text-center m-b-20">Recuperar contraseña</h3>

              <div className="form-group ">
                <div className="col-xs-12">
                  <input className="form-control" type="password" required={true} placeholder="Contraseña" value={password} onChange={(e) => setAndValidatePassword(e.target?.value)} />
                </div>
              </div>

              <div className="form-group ">
                <div className="col-xs-12">
                  <input className="form-control" type="password" required={true} placeholder="Repetir contraseña" value={passwordRepeat} onChange={(e) => setAndValidateRepeatPassword(e.target?.value)} />
                </div>
              </div>

              {error &&
                  <div className="form-group text-center">
                      <div className="col-xs-12">
                          <Alert>{error}</Alert>
                      </div>
                  </div>
              }

              {!error &&
                  <div className="form-group text-center">
                      <div className="col-xs-12 p-b-20">
                          <button className="btn btn-block btn-lg btn-info btn-rounded" type="submit">Cambiar contraseña</button>
                      </div>
                  </div>
              }

              <div className="form-group m-b-0">
                <div className="col-sm-12 text-center">Ya tienes cuenta?
                  <Link to={ERoutes.AUTH_LOGIN} className="text-info m-l-5"><b>Login</b></Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </Body>
}
