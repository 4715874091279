import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";
import ISession from "../../../interfaces/ISession";
import {setSession} from "../../../store/slices/sessionSlice";

export interface ILoginRequest {
  email: string;
  password: string;
  refreshToken: boolean;
  context: {
    latitude?: number;
    longitude?: number;
  }
}

export default async function loginRequest(
  dispatch: Dispatch,
  data: ILoginRequest,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('auth', 'login')
    .setData(data);

  const response = await request.makeRequest();

  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data) {
    dispatch(setSession(response.data))
  }

  dispatch(endLoading());

  return response;
}
