import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import ICircuit from "../../interfaces/ICircuit";
import {Buffer} from "buffer";

type SliceType = {
  circuit?: ICircuit;
}

const initialState: SliceType = {};

const slice = createSlice({
  name: EStores.SELECTED_COMPANY,
  initialState,
  reducers: {
    unselectCircuit(state: SliceType) {
      sessionStorage.removeItem('sc')
      return {}
    },
    setCircuit(state: SliceType, data: PayloadAction<ICircuit>) {
      sessionStorage.setItem('sc', JSON.stringify(data.payload))
      return {
        circuit: data.payload,
      }
    },
  },
});

export const {
  setCircuit,
  unselectCircuit,
} = slice.actions;

export default slice.reducer;
