import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IUser from "../../interfaces/IUser";

type SliceType = {
  user?: IUser;
}

const initialState: SliceType = {};

const slice = createSlice({
  name: EStores.USER_ME,
  initialState,
  reducers: {
    setUser(state: SliceType, data: PayloadAction<IUser>) {
      return {
        user: data.payload,
      }
    },
  },
});

export const {
  setUser,
} = slice.actions;

export default slice.reducer;
