import { useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import createProductRequest from "../../core/api/requests/circuits/products/createProductRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import ICircuitProduct from "../../core/interfaces/ICircuitProduct";
import { RootState } from "../../core/store/store";
import translator from "../../core/utils/translator";

type IInput = {
  onClose(): void;
  show: boolean;
};

export default function (data: IInput) {
  const dispatch = useDispatch();
  const selectedCircuit = useSelector(
    (state: RootState) => state.selectedCompany
  );

  const [product, setProduct] = useState<ICircuitProduct>();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);

  const save = async () => {
    if (!selectedCircuit.circuit) return;

    if (!name || !description || !duration || !price) {
      sweetalert2
        .fire(
          `Error`,
          `Debes completar todos los campos para continuar.`,
          "error"
        )
        .then(() => {
          data.onClose();
        });
      return;
    }

    const response = await createProductRequest(
      dispatch,
      selectedCircuit.circuit.id,
      {
        name,
        price,
        duration,
        currency: "EUR",
        status: "active",
        description,
      }
    );

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(`Excelente`, `Producto creado exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, translator(response.data.message), "error");
    }

    setName("");
    setPrice(0);
    setDuration(0);
    setDescription("");
  };

  return (
    <Modal size={"xl"} onHide={data.onClose} show={data.show}>
      <Modal.Header closeButton>
        <Modal.Title>Crear Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                e.target.value?.length < 30 ? setName(e.target.value) : () => {}
              }
              value={name}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Precio (€)</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setPrice(parseFloat(e?.target?.value || "0"))}
              value={price}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Duración de una tanda (s)</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setDuration(parseInt(e.target.value || "0"))}
              value={duration}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-3">
            <Form.Label>Número de tandas</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </Form.Group>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={data.onClose}>
          Cancelar
        </Button>

        <Button variant="success" onClick={save}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
