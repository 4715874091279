import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import IReport from "../../core/interfaces/IReport";
import moment from "moment";
import executeReportsRequest from "../../core/api/requests/reports/executeReportsRequest";
import cancelReportsRequest from "../../core/api/requests/reports/cancelReportsRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";

type IInput = {
  onClose(): void;
  show: boolean;
  report?: IReport;
}

export default function (data: IInput) {
  const dispatch = useDispatch()
  const [ file, setFile ] = useState<string>('')

  const execute = async () => {
    if (!data.report?.id) return

    const response = await executeReportsRequest(
      dispatch,
      data.report?.id
    )
    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Reporte ejecutado.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else if (response) {
      sweetalert2.fire(
        `Error`,
        'Error interno',
        'error',
      )
    }

  }

  const cancel = async () => {
    if (!data.report?.id) return

    const response = await cancelReportsRequest(
      dispatch,
      data.report?.id
    )

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Reporte desestimado.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else if (response) {
      sweetalert2.fire(
        `Error`,
        'Error interno',
        'error',
      )
    }
  }

  useEffect(() => {
    if (data.report) {
      const img = data?.report?.data?.content?.image || ''
      if (img) {
        setFile(img)
      }
    }

    if (!data.show) {
      setFile('')
    }
  }, [data.show]);

  if (!data.report) return <div></div>
  return <Modal size={'xl'} onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton>
      <Modal.Title>Administrar Reporte</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Form.Group className="mb-3 col-12">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={data.report?.id}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Tipo</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={data.report?.type}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={data.report?.status}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={`${data.report?.owner?.userName} - ${data.report?.owner?.email}`}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Fecha</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={moment(data.report.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          />
        </Form.Group>

        <Col sm={12}>
          <Form.Label>Data</Form.Label>
          <pre>
            {JSON.stringify(data.report.data, null, 2)}
          </pre>
        </Col>
      </Row>

      {file &&
        <div>
          <img className={'reports-image'} src={file} />
        </div>
      }
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="info"
        onClick={cancel}
      >
        Desestimar
      </Button>
      <Button
        variant="danger"
        onClick={execute}
      >
        Ejecutar acción
      </Button>
    </Modal.Footer>
  </Modal>
}
