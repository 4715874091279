import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import IReport from "../../../interfaces/IReport";

export default async function (
  dispatch: Dispatch,
  id: string,
): Promise<IResponse<IReport[]>> {
  dispatch(startLoading());

  const request = new RACARequest('reports', 'execute')
    .setPathValues('ID', id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
