import {useDispatch} from "react-redux";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ITip from "../../core/interfaces/ITip";
import moment from "moment";
import removeTipRequest from "../../core/api/requests/tips/removeTipRequest";
import sweetalert2 from "sweetalert2";
import translator from "../../core/utils/translator";
import updateTipRequest from "../../core/api/requests/tips/updateTipRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";

type IInput = {
  onClose(): void;
  show: boolean;
  tip?: ITip;
}

export default function (data: IInput) {
  const dispatch = useDispatch()

  const [ content, setContent ] = useState<string>(data.tip?.content.text || '')
  const [ title, setTitle ] = useState<string>(data.tip?.title || '')
  const [ owner, setOwner ] = useState<string>(data.tip?.owner || '')

  const remove = async () => {
    sweetalert2.fire({
      title: 'Atención!',
      text: `¿Seguro que desea eliminar este consejo?`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
    }).then((o) => {
      if (o.isConfirmed) removeRequest();
    });
  }

  const save = async () => {
    if (!data.tip?.id) return

    if (!title || !content || !owner) {
      sweetalert2.fire(
        `Atención`,
        `Debes completar todos los campos para continuar.`,
        'error',
      )
      return
    }

    const response = await updateTipRequest(
      dispatch,
      data.tip.id,
      {
        title,
        content: {
          text: content,
        },
        owner,
        type: 'tip',
        level: data.tip.level || '',
      }
    )

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Consejo actualizado exitosamente.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else {
      sweetalert2.fire(
        `Error`,
        translator(response?.data?.message),
        'error',
      )
    }

    data.onClose()
  }

  useEffect(() => {
    setOwner(data.tip?.owner || '')
    setTitle(data.tip?.title || '')
    setContent(data.tip?.content?.text || '')
  }, [data.tip]);

  const removeRequest = async () => {
    if (!data.tip?.id) return

    const response = await removeTipRequest(
      dispatch,
      data.tip.id
    )

    if (response && response.statusCode === 204) {
      sweetalert2.fire(
        `Excelente`,
        `Consejo eliminado exitosamente.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else {
      sweetalert2.fire(
        `Error`,
        'Error al eliminar',
        'error',
      )
    }
  }

  useEffect(() => {
    if (data.tip) {
      setContent(data.tip.content.text)
      setTitle(data.tip.title)
    }
  }, [data.tip]);

  if (!data.tip) return <div></div>
  return <Modal onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton>
      <Modal.Title>Administrar Consejo</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Form.Group className="mb-3 col-12">
          <Form.Label>ID</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={data.tip?.id}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Fecha</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={moment(data.tip.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Nivel</Form.Label>
          <Form.Control
            type="text"
            disabled
            value={translator(data.tip.level)}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Creador</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setOwner(e.target.value)}
            value={owner}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Contenido</Form.Label>
          <Form.Control
            as={"textarea"}
            rows={10}
            onChange={(e) => setContent(e.target.value)}
            value={content}
          />
        </Form.Group>
      </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>
      <Button
        variant="success"
        onClick={save}
      >
        Guardar
      </Button>
      <Button
        variant="danger"
        onClick={remove}
      >
        Eliminar
      </Button>
    </Modal.Footer>
  </Modal>
}
