import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import IBasicFilters from "../../../interfaces/IBasicFilters";
import requestMiddlewares from "../../requestMiddlewares";

export default async function findUsersRequest(
  dispatch: Dispatch,
  filters: IBasicFilters,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('users', 'list')
    .setQueryParameters(filters);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
