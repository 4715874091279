import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import {setFU} from "../../../store/slices/futilsSlice";

export default async function getFrontendUtilitiesRequest(
  dispatch: Dispatch,
): Promise<IResponse> {
  const request = new RACARequest('utils', 'futils')
  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  if (response.data) {
    dispatch(
      setFU(response.data),
    );
  }

  return response
}
