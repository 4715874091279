import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../Request";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import requestMiddlewares from "../../requestMiddlewares";

export interface IUserFindByIdRequest {
  id: string;
}

export default async function (
  dispatch: Dispatch,
  input: IUserFindByIdRequest,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('users', 'view')
    .setPathValues('ID', input.id);

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  )

  dispatch(endLoading());

  return response;
}
