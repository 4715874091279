import {Dispatch} from "redux";
import {IResponse, RACARequest} from "../../../Request";
import {endLoading, startLoading} from "../../../../store/slices/loadingSlice";
import requestMiddlewares from "../../../requestMiddlewares";

export interface ICreateCompanyRequest {
  name: string;
  description: string;
}

export default async function createOfferRequest(
  dispatch: Dispatch,
  input: ICreateCompanyRequest,
  id: string,
): Promise<IResponse> {
  dispatch(startLoading());
  const request = new RACARequest('circuits', 'offers-create')
    .setData(input).setPathValues('ID', id)

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
