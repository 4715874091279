enum EStores {
    LOADING = 'loading',
    SESSION = 'session',
    PERMISSIONS = 'permissions',
    AVAILABLE_CIRCUITS = 'availableCircuits',
    SELECTED_COMPANY = 'selectedCompany',
    USER_ME = 'userMe',
    FRONTEND_UTILITIES = 'frontendUtilities',
}

export default EStores;
