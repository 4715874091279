import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {RootState} from "../../core/store/store";
import {useNavigate, useParams} from "react-router-dom";
import IUser from "../../core/interfaces/IUser";
import ERoutes from "../../core/enums/ERoutes";
import userFindByIdRequest from "../../core/api/requests/users/userFindByIdRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import findRolesRequest from "../../core/api/requests/roles/findRolesRequest";
import IRole from "../../core/interfaces/IRole";
import moment from "moment";
import sweetalert2 from "sweetalert2";
import userRemoveRequest from "../../core/api/requests/users/userRemoveRequest";
import userUpdateRequest, {IUserUpdateRequest} from "../../core/api/requests/users/userUpdateRequest";
import ProfileImage from "../../shared/lib/ProfileImage";
import ReactCountryFlag from "react-country-flag";
import {convertIsoCountryCode} from "../../core/utils/countries";
import defaults from "../../core/utils/defaults";
import translator from "../../core/utils/translator";

const permission: IPermission = {
  module: EModules.USERS,
  method: ECommonPermissions.FIND_BY_ID,
}

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const params = useParams();

  const [ roles, setRoles ] = useState<IRole[]>([]);

  const [ user, setUser ] = useState<IUser>();

  const goBack = () => navigator(ERoutes.USERS_LIST)

  const get = async (id: string) => {
    const response = await userFindByIdRequest(
      dispatch,
      { id }
    );

    if (response.statusCode === EHttpStatusCodes.OK && response.data) {
      setUser(response.data)
    } else goBack()
  }

  const setUserValue = (
    field: string,
    value: any,
  ) => {
    if (!user) return

    const userTemp = user

    switch (field) {
      case 'roleId':
        const role = roles.find((r) => r.id === value);
        if (role) {
          userTemp.role = role;
        }
        break;
      case 'name':
        userTemp.name = value;
        break;
      case 'lastName':
        userTemp.lastName = value;
        break;
      case 'userName':
        userTemp.userName = value;
        break;
      default:
    }

    setUser({
      ...userTemp
    })
  }

  const findRoles = async () => {
    const response = await findRolesRequest(
      dispatch,
      {
        query: '',
        page: 1,
        perPage: 100,
      },
    )

    if (response?.data?.data) {
      setRoles(response.data.data);
    }
  }

  const removeUser = async () => {
    sweetalert2.fire({
      title: 'Atención!',
      text: `Estas por eliminar el usuario ${user?.email}`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
    }).then((o) => {
      if (o.isConfirmed) removeUserConfirmed();
    });
  }

  const removeUserConfirmed = async () => {
    if (!user) return;
    const response = await userRemoveRequest(
      dispatch,
      {
        id: user.id
      }
    )

    if (response.statusCode === EHttpStatusCodes.OK) {
      sweetalert2.fire(
        `Excelente`,
        `Usuario ${user.email} eliminado exitosamente.`,
        'success',
      ).then(() => goBack())
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  const updateUser = async () => {
    if (!user) return;

    const data: IUserUpdateRequest = {
      id: user.id,
      user: {
        name: user.name,
        lastName: user.lastName,
        roleId: user.role.id,
        status: user.status,
        userName: user.userName,
      }
    }

    const response = await userUpdateRequest(
      dispatch,
      data,
    );

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Usuario ${user.email} modificado exitosamente.`,
        'success',
      ).then(() => {
        setUser(response.data);
      })
    } else {
      sweetalert2.fire(
        `Error`,
        response.data.message,
        'error',
      )
    }
  }

  useEffect(() => {
    findRoles()

    if (params?.id) {
      get(params.id);
    } else goBack();
  }, [])

  return <SecureView permission={permission}>
    <Card className={'col-12'}>
      <CardBody>
        <Row>
          <Col sm={2} className={'mb-3'}>
            <ProfileImage
              uri={user?.profileImage || defaults.profileImage}
            />
          </Col>

          <Col sm={10}>
            <div className={'mb-1'}>
              <Badge bg={"primary"} className={'text-white'}>Estado: {user?.status}</Badge>
            </div>
            <div className={'mb-1'}>
              <Badge bg={"primary"} className={'text-white'}>T&C: {user?.termsOfCondition ? 'Aceptado' : 'No Aceptado'}</Badge>
            </div>
            <div className={'mb-1'}>
              <Badge bg={"primary"} className={'text-white'}>Primer Inicio: {user?.firstInit ? 'Aún no iniciado' : 'Iniciado'}</Badge>
            </div>
            <div className={'mb-1'}>
              <Badge bg={"primary"} className={'text-white'}>Plan de Suscripción: {user?.premium ? 'Premium' : 'Gratuito'}</Badge>
            </div>
            <div className={'mb-1'}>
              <Badge bg={"primary"} className={'text-white'}>Nivel: {user?.level.toFixed(2)}</Badge>
            </div>
          </Col>
        </Row>
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={user?.id}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Fecha de alta</Form.Label>
            <Form.Control
              type="text"
              value={user?.createdAt && moment(user.createdAt).format('DD/MM/YYYY HH:mm:ss')}
              disabled
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className="mb-3 col-4">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              placeholder="John"
              value={user?.name}
              onChange={(e) => setUserValue('name', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              value={user?.lastName}
              onChange={(e) => setUserValue('lastName', e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              placeholder="Doe"
              value={user?.userName}
              onChange={(e) => setUserValue('userName', e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group className="mb-3 col-4">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="example@racasf.eu"
              value={user?.email}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Rol</Form.Label>
            <select
              className={'custom-select'}
              value={user?.role.id}
              onChange={(e) => setUserValue('roleId', e.target.value)}
            >
              <option>Seleccionar rol</option>
              {roles.map((r) => {
                return <option
                  key={r.id}
                  value={r.id}
                  selected={r.id === user?.role.id}
                >
                  {r.name}
                </option>
              })
              }
            </select>
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Genero</Form.Label>
            <Form.Control
              type="text"
              value={translator(user?.gender)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Lenguaje</Form.Label>
            <Form.Control
              type="text"
              value={translator(user?.lang)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Fecha de nacimiento</Form.Label>
            <Form.Control
              type="text"
              value={moment(user?.birthDate).format('DD/MM/YYYY')}
              disabled
            />
          </Form.Group>
        </Row>

        <Row>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Experiencia</Form.Label>
            <Form.Control
              type="text"
              value={translator(user?.experience)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Condiciones físicas</Form.Label>
            <Form.Control
              type="text"
              value={translator(user?.physicalStatus)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Frecuencia últimos 6 meses</Form.Label>
            <Form.Control
              type="text"
              value={translator(user?.frequencyLastMonths)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Nivel de competición</Form.Label>
            <Form.Control
              type="text"
              value={translator(user?.competitionLevel)}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Educación en Kart</Form.Label>
            <Form.Control
              type="text"
              value={user?.kartingEducation}
              disabled
            />
          </Form.Group>

          <Form.Group className="mb-3 col-2">
            <Form.Label>Nacionalidad <ReactCountryFlag countryCode={convertIsoCountryCode(user?.nationality || 'ESP')} /></Form.Label>
            <Form.Control
              type="text"
              value={user?.nationality}
              disabled
            />


          </Form.Group>

        </Row>


        <Button variant="success" type="button" onClick={updateUser} className={'mt-3'}>
          <i className='ti-save'></i> Guardar
        </Button>

        <Button variant="danger" type="button" onClick={removeUser} className={'mt-3 ml-2'}>
          <i className='ti-trash'></i> Eliminar
        </Button>

        <Button variant="secondary" type="button" onClick={goBack} className={'mt-3 ml-2'}>
          Cancelar
        </Button>
      </CardBody>
    </Card>
  </SecureView>
}

