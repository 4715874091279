import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import IBasicFilters from "../../../interfaces/IBasicFilters";
import requestMiddlewares from "../../requestMiddlewares";
import IPager from "../../../interfaces/IPager";
import IJourney from "../../../interfaces/IJourney";

export default async function (
  dispatch: Dispatch,
  filters: IBasicFilters,
): Promise<IResponse<IPager<IJourney>>> {
  dispatch(startLoading());

  const request = new RACARequest('utils', 'list-journeys')
    .setQueryParameters({
      ...filters,
      status: 'verifying',
    });

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
