import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";
import IPager from "../../../interfaces/IPager";
import IFastLap from "../../../interfaces/IFastLap";

export default async function (
  dispatch: Dispatch,
  id: string,
  verified: boolean,
): Promise<IResponse<IPager<IFastLap>>> {
  dispatch(startLoading());

  const request = new RACARequest('utils', 'modify-fast-laps')
    .setPathValues('ID', id)
    .setData({
      action: 'fastLap.status',
      data: {
        status: verified ? 'verified' : 'notVerified',
      }
    },
  )

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
