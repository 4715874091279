import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sweetalert2 from "sweetalert2";
import accountActivationRequest from "../../../core/api/requests/auth/accountActivationRequest";
import EHttpStatusCodes from "../../../core/enums/EHttpStatusCodes";

export default function AccountActivationVerify() {
  const dipatch = useDispatch();
  const navigator = useNavigate();
  const { search } = useLocation();

  const request = async () => {
    const response = await accountActivationRequest(dipatch, {
      token: new URLSearchParams(search).get("t") || "",
    });

    if (response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(
          `Excelente`,
          `Cuenta Verificada con exito, vuelve a la app para iniciar sesión.`,
          "success"
        )
        .then(() => navigator("http://onpole.io/"));
    } else {
      sweetalert2
        .fire(`Error`, response.data.message, "error")
        .then(() => navigator("http://onpole.io/"));
    }
  };

  useEffect(() => {
    request();
  }, []);

  return <div></div>;
}
