import { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import removeRaceRequest from "../../core/api/requests/circuits/racing/removeRaceRequest";
import updateRaceRequest from "../../core/api/requests/circuits/racing/updateRaceRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import ICircuitRace from "../../core/interfaces/ICircuitRace";
import { RootState } from "../../core/store/store";
import translator from "../../core/utils/translator";

type IInput = {
  onClose(): void;
  show: boolean;
  race?: ICircuitRace;
};

export default function (data: IInput) {
  const dispatch = useDispatch();
  const selectedCircuit = useSelector(
    (state: RootState) => state.selectedCompany
  );

  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [peopleCount, setPeopleCount] = useState<number>(0);

  useEffect(() => {
    setTitle(data.race?.title || "");
    setDescription(data.race?.description || "");
    setPrice(data.race?.price || 0);
    setPeopleCount(data.race?.peopleCount || 0);
  }, [data.race]);

  const remove = () => {
    sweetalert2
      .fire({
        title: "Atención!",
        text: `¿Seguro que desea eliminar esta carrera?`,
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        icon: "question",
      })
      .then((o) => {
        if (o.isConfirmed) removeRequest();
      });
  };

  const save = async () => {
    if (!selectedCircuit.circuit || !data.race?.id) return;

    if (
      !title ||
      !description ||
      !price ||
      (peopleCount === null && peopleCount === undefined)
    ) {
      sweetalert2.fire(
        `Atención`,
        `Debes completar todos los campos para continuar.`,
        "error"
      );
      return;
    }

    const response = await updateRaceRequest(
      dispatch,
      data.race.id,
      selectedCircuit.circuit.id,
      {
        title,
        price,
        peopleCount,
        description,
      }
    );

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(`Excelente`, `Carrera modificada exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, translator(response.data.message), "error");
    }

    setTitle("");
    setDescription("");
    setPrice(0);
    setPeopleCount(0);
    data.onClose();
  };

  const removeRequest = async () => {
    if (!data.race || !selectedCircuit.circuit) return;

    const response = await removeRaceRequest(
      dispatch,
      selectedCircuit.circuit.id,
      data.race.id
    );

    if (response && response.statusCode === 202) {
      sweetalert2
        .fire(`Excelente`, `Carrera eliminada exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, "Error al eliminar", "error");
    }
  };

  if (!data.race) return <div></div>;
  return (
    <Modal size={"xl"} onHide={data.onClose} show={data.show}>
      <Modal.Header closeButton>
        <Modal.Title>Administrar Carrera</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>ID</Form.Label>
            <Form.Control type="text" disabled value={data.race?.id} />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) =>
                e.target.value?.length < 30
                  ? setTitle(e.target.value)
                  : () => {}
              }
              value={title}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Precio ({data.race.currency.symbol})</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setPrice(parseFloat(e?.target?.value || "0"))}
              value={price}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Cantidad mínima de pilotos</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setPeopleCount(parseInt(e.target.value || "0"))}
              value={peopleCount}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-12">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={data.onClose}>
          Cancelar
        </Button>

        <Button variant="success" onClick={save}>
          Guardar
        </Button>

        <Button variant="danger" onClick={remove}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
