interface ICardParameter {
    children: any;
    className: string;
}

export default function Card(data: ICardParameter) {
    return <div className={data.className}>
        <div className="card">
            {data.children && data.children}
        </div>
    </div>
}
