  import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './shared/styles/globals.css'
import './shared/styles/main.css'
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./core/store/store";

import * as Sentry from "@sentry/react";
//
// Sentry.init({
//   dsn: "https://58ca60e44c7534a8241055744f1c62b4@o4505929567633408.ingest.sentry.io/4505929864118272",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ["api.marketdash.mx"],
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
