import moment from "moment";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import sweetalert2 from "sweetalert2";
import modifyFastLapRequest from "../../core/api/requests/utils/modifyFastLapRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import IFastLap from "../../core/interfaces/IFastLap";
import translator from "../../core/utils/translator";

type IInput = {
  onClose(): void;
  show: boolean;
  fastLap?: IFastLap;
};

export default function (data: IInput) {
  const dispatch = useDispatch();

  const approve = async () => {
    if (!data.fastLap?.id) return;

    const response = await modifyFastLapRequest(
      dispatch,
      data.fastLap.id,
      true
    );

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(`Excelente`, `Registro aprobado exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, "Error al aprobar", "error");
    }
  };

  const deny = async () => {
    if (!data.fastLap?.id) return;

    const response = await modifyFastLapRequest(
      dispatch,
      data.fastLap.id,
      false
    );

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2
        .fire(`Excelente`, `Registro desaprobado exitosamente.`, "success")
        .then(() => {
          data.onClose();
        });
    } else {
      sweetalert2.fire(`Error`, "Error al desaprobar", "error");
    }
  };

  if (!data.fastLap) return <div></div>;
  return (
    <Modal size={"xl"} onHide={data.onClose} show={data.show}>
      <Modal.Header closeButton>
        <Modal.Title>Validar Vuelta Rápida</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Form.Group className="mb-3 col-6">
            <Form.Label>ID</Form.Label>
            <Form.Control type="text" disabled value={data.fastLap?.id} />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Fecha</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={moment(data.fastLap.date).format("DD/MM/YYYY HH:mm:ss")}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Circuito ID</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={data.fastLap?.circuit.id}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Nombre del Circuito</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={data.fastLap?.circuit.name}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={data.fastLap?.user.userName}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-6">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={data.fastLap?.user.email}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Kart</Form.Label>
            <Form.Control type="text" disabled value={data.fastLap?.kart} />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Vuelta Refencia</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={
                data.fastLap.circuit.kartingTimes
                  ?.filter(
                    (kartingTime) => data.fastLap?.kart === kartingTime.kart
                  )
                  .reduce((best: any, current: any) => {
                    if (!best || current.time < best.time) {
                      return current;
                    } else {
                      return best;
                    }
                  }, null).time
              }
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              type="text"
              disabled
              value={translator(data.fastLap?.status)}
            />
          </Form.Group>

          <Form.Group className="mb-3 col-4">
            <Form.Label>Tiempo</Form.Label>
            <Form.Control type="text" disabled value={data.fastLap?.time} />
          </Form.Group>

          <Col sm={12}>
            <embed
              src={data.fastLap.validationFile}
              width={"100%"}
              height={900}
            />
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={data.onClose}>
          Cancelar
        </Button>

        {data.fastLap.status !== "verified" && (
          <div>
            <Button variant="danger" onClick={deny}>
              Denegar
            </Button>
            <Button variant="success" className={"ml-2"} onClick={approve}>
              Aprobar
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
