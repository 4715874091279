import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import {Badge, Button, Form, InputGroup, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import IReport from "../../core/interfaces/IReport";
import findReportsRequest from "../../core/api/requests/reports/findReportsRequest";
import moment from "moment";
import SeeReportModal from "../../shared/modals/SeeReportModal";
import translator from "../../core/utils/translator";

const permission: IPermission = {
  module: EModules.CIRCUITS,
  method: ECommonPermissions.FIND,
}

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ reports, setReports ] = useState<IReport[]>([]);

  const [ selectedReport, setSelectedReport ] = useState<IReport>()

  const find = async () => {

    const response = await findReportsRequest(
      dispatch,
    )

    if (response.data) {
      setReports(response.data)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    find()
  }, [selectedReport])

  return <SecureView permission={permission}>
    <SeeReportModal
      report={selectedReport}
      show={!!selectedReport}
      onClose={() => setSelectedReport(undefined)}
    />

    <Row>
      <Card className={'col-12'}>
        <CardBody title={`Denuncias ${reports.length}`}>
          <div className="table-responsive">
            <table className="table table-bordered m-t-30 table-hover contact-list">
              <thead>
              <tr>
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Estado</th>
                <th>Creador</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              {reports.map((report) => {
                return <tr key={report.id}>
                  <td>{moment(report.createdAt).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>
                    <Badge bg={'warning'}>{translator(report.type)}</Badge>
                  </td>
                  <td>
                    <Badge bg={'info'}>{translator(report.status)}</Badge>
                  </td>
                  <td>{report.owner?.userName} - {report.owner?.email}</td>
                  <td>
                    <Button size={'sm'} variant={'secondary'} onClick={() => setSelectedReport(report)}>
                      <i className={'ti-eye'}></i>
                    </Button>
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </Row>
  </SecureView>
}
