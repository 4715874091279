import {ProgressBar} from "react-bootstrap";

export interface IComponent {
  variant?: string;
  name: string;
  value: string | number;
  now?: number
}

export default function (props: IComponent) {
  return <div className={'badge-raca'}>
    <h4>{props.name}</h4>
    <h1>{props.value}</h1>
    <ProgressBar style={{ height: 10 }} now={props.now || 0} variant={props.variant || 'info'} />
  </div>
}
