export default {
  profileImage:
    "https://onpole-api-resources.s3.eu-west-1.amazonaws.com/app-resources/Perfil_-_Foto_Predeterminada_1-removebg-preview.png",
  circuitCover:
    "https://onpole-api-resources.s3.eu-west-1.amazonaws.com/app-resources/circuit-default.jpg",
  tips: "https://onpole-api-resources.s3.eu-west-1.amazonaws.com/app-resources/tips-default.jpg",
  custom:
    "https://st4.depositphotos.com/14953852/24787/v/450/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg",
  events:
    "https://onpole-api-resources.s3.eu-west-1.amazonaws.com/app-resources/events-default.jpg",
  league:
    "https://onpole-api-resources.s3.eu-west-1.amazonaws.com/app-resources/Liga+-+Foto+Predeterminada.jpg.png",
  create:
    "https://cdn5.vectorstock.com/i/1000x1000/99/64/create-icon-images-vector-34189964.jpg",
};
