import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import ICircuit from "../../interfaces/ICircuit";

const initialState: ICircuit[] = [];

const availableCircuitsSlice = createSlice({
  name: EStores.AVAILABLE_CIRCUITS,
  initialState,
  reducers: {
    setCircuits(state: ICircuit[], data: PayloadAction<ICircuit[]>) {
      return data.payload;
    },
  },
});

export const {
  setCircuits,
} = availableCircuitsSlice.actions;

export default availableCircuitsSlice.reducer;
