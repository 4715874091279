import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import EStores from '../../enums/EStores';
import IUser from "../../interfaces/IUser";
import IFrontendUtilities from "../../interfaces/IFrontendUtilities";

type SliceType = {
  futils?: IFrontendUtilities;
}

const initialState: SliceType = {};

const slice = createSlice({
  name: EStores.FRONTEND_UTILITIES,
  initialState,
  reducers: {
    setFU(state: SliceType, data: PayloadAction<IFrontendUtilities>) {
      return {
        futils: data.payload,
      }
    },
  },
});

export const {
  setFU,
} = slice.actions;

export default slice.reducer;
