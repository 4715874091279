import {Dispatch} from "redux";
import {endLoading, startLoading} from "../../../store/slices/loadingSlice";
import {IResponse, RACARequest} from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";

export default async function (
  dispatch: Dispatch,
  email: string,
  id: string,
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest('circuits', 'remove-user')
    .setPathValues('ID', id)
    .setData({
      email,
    })

  const response = await request.makeRequest();
  requestMiddlewares(
    dispatch,
    response,
  );

  dispatch(endLoading());

  return response;
}
