import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ICircuitRace from "../../core/interfaces/ICircuitRace";
import findProductRequest from "../../core/api/requests/circuits/products/findProductRequest";
import SecureView from "../../shared/common/SecureView";
import {Badge, Button, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import Paginator from "../../shared/common/Paginator";
import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import findRacingRequest from "../../core/api/requests/circuits/racing/findRacingRequest";
import SeeCircuitRaceModal from "../../shared/modals/SeeCircuitRaceModal";
import CreateCircuitRaceModal from "../../shared/modals/CreateCircuitRaceModal";

const permission: IPermission = {
  module: EModules.CIRCUIT_RACING,
  method: ECommonPermissions.FIND,
}

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const selectedCircuit = useSelector((state: RootState) => state.selectedCompany)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ racing, setRacing ] = useState<ICircuitRace[]>([]);

  const [ count, setCount ] = useState<number>(0);

  const [ totalPages, setTotalPages ] = useState<number>(1);
  const [ page, setPage ] = useState<number>(1);
  const [ perPage, setPerPage ] = useState<number>(30);
  const [ query, setQuery ] = useState<string>('');

  const [ createModal, setCreateModal ] = useState<boolean>(false)
  const [ seeModal, setSeeModal ] = useState<ICircuitRace>()

  const changePageAndFind = (page: number) => {
    find(page);
  }

  const find = async (pageByParam?: number) => {
    if (!selectedCircuit.circuit) return

    if (pageByParam) setPage(pageByParam)

    const response = await findRacingRequest(
      dispatch,
      {
        page: pageByParam || page,
        perPage,
        query,
      },
      selectedCircuit.circuit.id,
    )

    if (response?.data) {
      setRacing(response.data?.data || [])
      setCount(response.data?.count || 0)

      setPage(response.data?.pagination?.page || 1)
      setPerPage(response.data?.pagination?.perPage || 30)
      setTotalPages(response.data?.pagination?.total || 1)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    find()
  }, [seeModal, createModal, selectedCircuit.circuit])

  useEffect(() => {
    setPage(1)
  }, [query])

  return <SecureView permission={permission}>
    <SeeCircuitRaceModal
      show={!!seeModal}
      race={seeModal}
      onClose={() => setSeeModal(undefined)}
    />

    <CreateCircuitRaceModal
      show={createModal}
      onClose={() => setCreateModal(false)}
    />

    <Row>
      <Card className={'col-12'}>
        <CardBody title={`Carreras ${count}`}>
          <div className={'offset-9 col-3'}>
            <Button
              onClick={() => setCreateModal(true)}
              className="btn btn-info float-right"
            >Nueva carrera
            </Button>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered m-t-30 table-hover contact-list">
              <thead>
              <tr>
                <th>Nombre</th>
                <th>Precio</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              {racing.map((p) => {
                return <tr key={p.id}>
                  <td>{p.title}</td>
                  <td>{p.price} {p.currency.symbol}</td>
                  <td>
                    <Button size={'sm'} variant={'secondary'} onClick={() => setSeeModal(p)}>
                      <i className={'ti-eye'}></i> Ver
                    </Button>
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>

            <Paginator
              total={totalPages}
              page={page}
              goTo={changePageAndFind}
            />
          </div>


        </CardBody>
      </Card>
    </Row>
  </SecureView>
}
