import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import sweetalert2 from "sweetalert2";
import ICircuitEvent from "../../core/interfaces/ICircuitEvent";
import { RootState } from "../../core/store/store";
import translator from "../../core/utils/translator";
import ProfileImage from "../lib/ProfileImage";

import findByIdEventRequest from "../../core/api/requests/circuits/events/findByIdEventRequest";
import updateEventRequest from "../../core/api/requests/circuits/events/updateEventRequest";
import uploadEventPictureRequest from "../../core/api/requests/circuits/events/uploadEventPictureRequest";
import EHttpStatusCode from "../../core/enums/EHttpStatusCodes";
import Defaults from "../../core/utils/defaults";
type IInput = {
	onClose(): void;
	show: boolean;
	id: string;
};

export default function (data: IInput) {
	const dispatch = useDispatch();
	const selectedCircuit = useSelector(
		(state: RootState) => state.selectedCompany
	);

	const [event, setEvent] = useState<ICircuitEvent>();
	const [karts, setKarts] = useState<string[]>([]);

	useEffect(() => {
		if (data.show) {
			if (
				selectedCircuit?.circuit?.kartingTypes &&
				selectedCircuit?.circuit?.kartingTypes?.length > 0
			) {
				{
				}
			}
			setKarts(selectedCircuit?.circuit?.kartingTypes || []);
		}
	}, [data.show]);

	const get = async () => {
		if (!data.id || !selectedCircuit.circuit) return;

		const response = await findByIdEventRequest(dispatch, data.id);

		if (response) {
			setEvent(response.data);
			console.log(response.data);
		}
	};

	useEffect(() => {
		get();
	}, [data.id]);

	const changeImage = async (file: File) => {
		if (!file || !selectedCircuit.circuit?.id) return;

		await uploadEventPictureRequest(
			dispatch,
			file,
			selectedCircuit.circuit.id,
			data.id
		);
		get();
	};

	const save = async () => {
		if (!selectedCircuit.circuit) return;

		// console.log(name, description, date, kart);

		if (
			!event ||
			!event.name ||
			!event.description ||
			!event.startDate ||
			!event.kart
		) {
			sweetalert2.fire(
				`Atención`,
				`Debes completar todos los campos para continuar.`,
				"error"
			);
			return;
		}

		const response = await updateEventRequest(dispatch, event!);

		if (response && response.statusCode === EHttpStatusCode.ACCEPTED) {
			sweetalert2
				.fire(`Excelente`, `Evento Actualizado exitosamente.`, "success")
				.then(() => {
					data.onClose();
				});
		} else {
			sweetalert2.fire(`Error`, translator(response.data.message), "error");
		}
	};

	if (!event) return <div></div>;
	return (
		<Modal onHide={data.onClose} show={data.show}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Evento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col sm={12}>
						<ProfileImage
							uri={event.image || Defaults.events}
							onChange={changeImage}
						/>
					</Col>
					<Form.Group className="mb-3 col-12">
						<Form.Label>Fecha</Form.Label>
						<Form.Control
							type="datetime-local"
							value={moment(event.startDate).format("YYYY-MM-DD HH:mm:ss")}
							onChange={(e) =>
								setEvent({ ...event, startDate: new Date(e.target.value) })
							}
						/>
					</Form.Group>

					{/*<Form.Group className="mb-3 col-12">*/}
					{/*  <Form.Label>Fecha Finalización</Form.Label>*/}
					{/*  <Form.Control*/}
					{/*    type="datetime-local"*/}
					{/*    value={moment(dateEnd).format('YYYY-MM-DD HH:mm:ss')}*/}
					{/*    onChange={(e) => setDateEnd(new Date(e.target.value))}*/}
					{/*  />*/}
					{/*</Form.Group>*/}

					<Form.Group className="mb-3 col-12">
						<Form.Label>Nombre</Form.Label>
						<Form.Control
							type="text"
							placeholder={"Carrera de Resistencia de 1h"}
							onChange={(e) => setEvent({ ...event, name: e.target.value })}
							value={event.name}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Kart</Form.Label>
						<Form.Select
							value={event.kart}
							className={"col-12"}
							onChange={(e) => setEvent({ ...event, kart: e.target.value })}
						>
							{karts.map((k) => (
								<option key={k} value={k}>
									{translator(k)}
								</option>
							))}
						</Form.Select>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Precio (€)</Form.Label>
						<Form.Control
							type="number"
							onChange={(e) =>
								setEvent({ ...event, price: Number(e.target.value) })
							}
							value={event.price}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Inscripciones maximas</Form.Label>
						<Form.Control
							type="number"
							onChange={(e) =>
								setEvent({ ...event, maxUser: Number(e.target.value) })
							}
							value={event.maxUser}
						/>
					</Form.Group>

					<Form.Group className="mb-3 col-12">
						<Form.Label>Descripción</Form.Label>
						<Form.Control
							type="text"
							placeholder={
								"Entrenamientos cronometrados de 10 minutos + Carrera de 50 minutos."
							}
							onChange={(e) =>
								setEvent({ ...event, description: e.target.value })
							}
							value={event.description}
						/>
					</Form.Group>
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={data.onClose}>
					Cancelar
				</Button>

				<Button variant="success" onClick={save}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
