enum ERoutes {
  HOME = "/",

  AUTH_LOGIN = "/auth/login",
  AUTH_LOGOUT = "/auth/logout",
  AUTH_PASSWORD_RECOVERY_REQUEST = "/auth/password-recovery",

  VERIFY_PASSWORD_RECOVERY = "/verify/password-recovery",
  VERIFY_ACCOUNT_ACTIVATION = "/verify/account-activation",

  DASHBOARD = "/dashboard",

  USERS_LIST = "/users",
  USERS_VIEW = "/users/:id",
  USERS_CREATE = "/users/new",

  CIRCUITS_LIST = "/circuits",
  CIRCUITS_VIEW = "/circuits/:id",
  CIRCUITS_CREATE = "/circuits/new",

  TIPS_LIST = "/tips",

  REPORTS_LIST = "/reports",

  VALIDATIONS_LIST = "/validations",

  CIRCUIT_PRODUCT_LIST = "/circuit/products",

  CIRCUIT_OFFER_LIST = "/circuit/offers",

  CIRCUIT_RACING_LIST = "/circuit/racing",

  CIRCUIT_EVENTS_LIST = "/circuit/events",

  MY_CIRCUIT = "/circuit/me",
  FORCE_PASSWORD_CHANGE = "/auth/force-password-change",
}

export default ERoutes;
