import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Form, Modal, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {RootState} from "../../core/store/store";
import ICircuitProduct from "../../core/interfaces/ICircuitProduct";
import findByIdProductRequest from "../../core/api/requests/circuits/products/findByIdProductRequest";
import updateProductRequest from "../../core/api/requests/circuits/products/updateProductRequest";
import EHttpStatusCodes from "../../core/enums/EHttpStatusCodes";
import sweetalert2 from "sweetalert2";
import createProductRequest from "../../core/api/requests/circuits/products/createProductRequest";
import ICircuitRace from "../../core/interfaces/ICircuitRace";
import createRaceRequest from "../../core/api/requests/circuits/racing/createRaceRequest";
import translator from "../../core/utils/translator";

type IInput = {
  onClose(): void;
  show: boolean;
}

export default function (data: IInput) {
  const dispatch = useDispatch()
  const selectedCircuit = useSelector((state: RootState) => state.selectedCompany)

  const [ race, setRace ] = useState<ICircuitRace>()

  const [ name, setName ] = useState<string>('')
  const [ description, setDescription ] = useState<string>('')
  const [ price, setPrice ] = useState<number>(0)
  const [ peopleCount, setPeopleCount ] = useState<number>(0)

  const save = async () => {
    if (!selectedCircuit.circuit) return;

    if (!name || !description || !price || !peopleCount) {
      sweetalert2.fire(
        `Atención`,
        `Debes completar todos los campos para continuar.`,
        'error',
      )
      return
    }

    const response = await createRaceRequest(
      dispatch,
      selectedCircuit.circuit.id,
      {
        title: name,
        price,
        peopleCount,
        currency: 'EUR',
        description,
      }
    )

    if (response && response.statusCode === EHttpStatusCodes.ACCEPTED) {
      sweetalert2.fire(
        `Excelente`,
        `Carrera creada exitosamente.`,
        'success',
      ).then(() => {
        data.onClose()
      })
    } else {
      sweetalert2.fire(
        `Error`,
        translator(response.data.message),
        'error',
      )
    }

    setName('')
    setDescription('')
    setPrice(0)
    setPeopleCount(0)
  }

  return <Modal size={'xl'} onHide={data.onClose} show={data.show}>
    <Modal.Header closeButton>
      <Modal.Title>Crear Carrera</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Row>
        <Form.Group className="mb-3 col-4">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            placeholder={'Gran Premio Oro'}
            onChange={(e) => e.target.value?.length < 30 ? setName(e.target.value) : () => {}}
            value={name}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-4">
          <Form.Label>Precio (€)</Form.Label>
          <Form.Control
            type="number"
            onChange={(e) => setPrice(parseFloat(e?.target?.value || '0'))}
            value={price}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-4">
          <Form.Label>Cantidad mínima de pilotos</Form.Label>
          <Form.Control
            type="number"
            onChange={(e) => setPeopleCount(parseInt(e.target.value || '0'))}
            value={peopleCount}
          />
        </Form.Group>

        <Form.Group className="mb-3 col-12">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            type="text"
            placeholder={'Entrenamientos cronometrados de 10 minutos + Carrera de 10 vueltas.'}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </Form.Group>
      </Row>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={data.onClose}>
        Cancelar
      </Button>

      <Button
        variant="success"
        onClick={save}
      >
        Guardar
      </Button>
    </Modal.Footer>
  </Modal>
}
