export default {
  levels: [
    {
      key: 'beginner',
      label: 'Principiante',
      help: 'sdadadsadas',
    },
    {
      key: 'intermediate',
      label: 'Intermedio',
      help: 'sdadadsadas',
    },
    {
      key: 'advance',
      label: 'Avanzado',
      help: 'sdadadsadas',
    },
    {
      key: 'expert',
      label: 'Experto',
      help: 'sdadadsadas',
    },
    {
      key: 'professional',
      label: 'Profesional',
      help: 'sdadadsadas',
    },
  ],
  levelsSelect: [
    {
      key: 'beginner',
      value: 'Principiante',
    },
    {
      key: 'intermediate',
      value: 'Intermedio',
    },
    {
      key: 'advance',
      value: 'Avanzado',
    },
    {
      key: 'expert',
      value: 'Experto',
    },
    {
      key: 'professional',
      value: 'Profesional',
    },
  ],
  maxPilotsSelect: [
    {
      key: '10',
      value: '10',
    },
    {
      key: '20',
      value: '20',
    },
    {
      key: '30',
      value: '30',
    },
    {
      key: '40',
      value: '40',
    },
    {
      key: '50',
      value: '50',
    },
  ],
  kartKnowTime: [
    { key: 'lessYear', label: 'Menos de un año' },
    { key: 'lessThreeYears', label: 'Menos de 3 años' },
    { key: 'lessFiveYears', label: 'Menos de 5 años' },
    { key: 'moreFiveYears', label: 'Más de 5 años' },
  ],
  kartTimeLast6Months: [
    { key: '0', label: '0' },
    { key: '1', label: '1' },
    { key: '2', label: '2' },
    { key: '5', label: '3 o más' },
  ],
  kartEducation: [
    { key: '0', label: 'No, nunca' },
    { key: '1', label: 'Sí, una vez' },
    { key: '2', label: 'Sí, dos veces' },
    { key: '3', label: 'Sí, tres o más veces' },
  ],
  physicalStatus: [
    { key: 'excellent', label: 'Excelente' },
    { key: 'good', label: 'Bueno' },
    { key: 'normal', label: 'Normal' },
    { key: 'bad', label: 'Bajo' },
    { key: 'veryBad', label: 'Muy Bajo' },
  ],
  maxLevel: 7,
}
