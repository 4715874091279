import { Dispatch } from "redux";
import { endLoading, startLoading } from "../../../store/slices/loadingSlice";
import { IResponse, RACARequest } from "../../Request";
import requestMiddlewares from "../../requestMiddlewares";

export interface IForcePasswordChangeRequest {
  password: string;
}

export default async function (
  dispatch: Dispatch,
  input: IForcePasswordChangeRequest
): Promise<IResponse> {
  dispatch(startLoading());

  const request = new RACARequest("auth", "force-password-change").setData(
    input
  );

  const response = await request.makeRequest();
  requestMiddlewares(dispatch, response);

  dispatch(endLoading());
  return response;
}
