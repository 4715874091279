interface cardBodyParameters {
    children: any;
    title?: string;
}

export default function CardBody(data: cardBodyParameters) {
    return (
        <div className={"card-body"}>
            {data.title &&
              <h4 className="card-title">{data.title}</h4>
            }
            {data.children && data.children}
        </div>
    )
}
