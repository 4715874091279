import IPermission from "../../core/interfaces/IPermission";
import EModules from "../../core/enums/EModules";
import ECommonPermissions from "../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../core/store/store";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import SecureView from "../../shared/common/SecureView";
import {Button, Form, InputGroup, Row} from "react-bootstrap";
import Card from "../../shared/lib/Card";
import CardBody from "../../shared/lib/CardBody";
import ERoutes from "../../core/enums/ERoutes";
import Paginator from "../../shared/common/Paginator";
import ICircuitOffer from "../../core/interfaces/ICircuitOffer";
import findOfferRequest from "../../core/api/requests/circuits/offers/findOfferRequest";
import CreateOfferModal from "../../shared/modals/CreateOfferModal";
import removeOfferRequest from "../../core/api/requests/circuits/offers/removeOfferRequest";
import sweetalert2 from "sweetalert2";

const permission: IPermission = {
  module: EModules.CIRCUIT_PRODUCTS,
  method: ECommonPermissions.FIND,
}

export default function () {
  const permissions = useSelector((state: RootState) => state.permissions)
  const selectedCircuit = useSelector((state: RootState) => state.selectedCompany)
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [ offers, setOffers ] = useState<ICircuitOffer[]>([]);

  const [ createModal, setCreateModal ] = useState<boolean>(false);

  const find = async () => {
    if (!selectedCircuit?.circuit?.id) return

    const response = await findOfferRequest(
      dispatch,
      selectedCircuit.circuit.id,
    )

    if (response.data) {
      setOffers(response.data)
    }
  }

  useEffect(() => {
    find()
  }, [])

  useEffect(() => {
    find()
  }, [createModal, selectedCircuit.circuit])

  const remove = async (id: string) => {
    sweetalert2.fire({
      title: 'Atención!',
      text: `¿Seguro que desea eliminar esta oferta?`,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      icon: 'question',
    }).then((o) => {
      if (o.isConfirmed) removeRequest(id);
    });
  }

  const removeRequest = async (id: string) => {
    if (!selectedCircuit?.circuit?.id) return

    const response = await removeOfferRequest(
      dispatch,
      selectedCircuit.circuit.id,
      id,
    )

    if (response && response.statusCode === 202) {
      sweetalert2.fire(
        `Excelente`,
        `Oferta eliminada exitosamente.`,
        'success',
      ).then(() => {
        find()
      })
    } else {
      sweetalert2.fire(
        `Error`,
        'Error al eliminar',
        'error',
      )
    }
  }

  return <SecureView permission={permission}>
    <CreateOfferModal
      show={createModal}
      onClose={() => setCreateModal(false)}
    />

    <Row>
      <Card className={'col-12'}>
        <CardBody title={`Ofertas ${offers.length}`}>
          <div className={'offset-9 col-3'}>
            <Button
              onClick={() => setCreateModal(true)}
              className="btn btn-info float-right"
            >Nueva Oferta
            </Button>
          </div>

          <div className="table-responsive">
            <table className="table table-bordered m-t-30 table-hover contact-list">
              <thead>
              <tr>
                <th>Nombre</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              {offers.map((o) => {
                return <tr key={o.id}>
                  <td>{o.name}</td>
                  <td>
                    <Button size={'sm'} variant={'danger'} onClick={() => remove(o.id)}>
                      <i className={'ti-trash'}></i> Eliminar
                    </Button>
                  </td>
                </tr>
              })
              }
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </Row>
  </SecureView>
}
