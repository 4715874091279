import {configureStore} from "@reduxjs/toolkit";

import loadingSlice from "./slices/loadingSlice";
import sessionSlice from "./slices/sessionSlice";
import permissionsSlice from "./slices/permissionsSlice";
import availableCircuits from "./slices/availableCircuitsSlice";
import selectedCompanySlice from "./slices/selectedCompanySlice";
import futilsSlice from "./slices/futilsSlice";
import userMeSlice from "./slices/userMeSlice";

const store = configureStore({
    reducer: {
        loading: loadingSlice,
        session: sessionSlice,
        permissions: permissionsSlice,
        availableCircuits: availableCircuits,
        selectedCompany: selectedCompanySlice,
        frontendUtilities: futilsSlice,
        userMe: userMeSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
